import React, { useEffect, useState, useContext } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory
  } from "react-router-dom";
import { ReactSVG } from 'react-svg'
import { auth, firestore } from "../../services/firebase";
import GlobalState from '../../context/GlobalState';
import context from '../../context/context';
import "fontsource-clear-sans"
import smalllogo from '../../smalllogo.svg';

function FourOFour() {
    const history = useHistory();
    const { completed, setAllCompleted } = useContext(context);

  return (
    <div style={{ 
        display: 'flex', 
        flexDirection: 'column', 
        flex: 1, 
        alignItems: 'center', 
        height: '100vh', 
        fontFamily: "Clear Sans, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif", 
    }}>
        <div style={{ 
            marginBottom: '6rem', 
            display: 'flex', 
            flexDirection: 'row', 
            width: '80rem', 
            borderBottomWidth: '0.225rem',
            borderBottomStyle: 'solid',
            borderBottomColor: 'rgb(236, 240, 241)'
        }}>
            <div style={{ width: '40%', height: '4.5rem' }}>
                <Link to="/"> 
                    <ReactSVG beforeInjection={(svg) => {
                        svg.classList.add('svg-class-name')
                        svg.setAttribute('style', 'height: 4.5rem; width: 4rem; padding: 0.75rem 0 0.75rem 0.75rem')
                    }} src={smalllogo} style={{marginBottom: '0.75rem'}} />
                </Link>
            </div>
            <div style={{ display: 'flex', width: '60%', alignItems: 'center', justifyContent: 'flex-end' }}>
                <div style={{ display: 'flex', marginRight: '-1rem', flexDirection: 'row', alignItems: 'center' }}>
                    <Link style={{ paddingRight: '3rem', fontSize: '1.1rem', fontWeight: 400, lineHeight: '1.5rem', opacity: 0.5, color: '#000' }} to="/questionlist">
                        Problem Index
                    </Link>
                    <Link style={{ paddingRight: '3rem', fontSize: '1.1rem', fontWeight: 400, lineHeight: '1.5rem', opacity: 0.5, color: '#000' }} onClick={(e) => {
                        e.preventDefault();
                        const completedQuestions = Object.fromEntries(
                            Object.entries(completed).filter(([key, value]) => value === false) )
                        //console.log(Object.keys(completedQuestions).length*Math.random())
                        let randomIndex = 0
                        let linkTo = `/q/${Object.keys(completed)[randomIndex]}`
                        //console.log(Object.keys(completedQuestions).length)
                        if (Object.keys(completedQuestions).length*Math.random() === 0) {
                            randomIndex = Math.floor((Object.keys(completed).length)*Math.random())
                            linkTo = `/q/${Object.keys(completed)[randomIndex]}`
                        } else {
                            randomIndex = Math.floor((Object.keys(completedQuestions).length)*Math.random())
                            linkTo = `/q/${Object.keys(completedQuestions)[randomIndex]}`
                        }
                        
                        //console.log("linkTo", linkTo)
                        history.push(`${linkTo}`)
                    }}>
                        Problem Generator
                    </Link>
                    {auth().currentUser ? 
                    <Link style={{ paddingRight: '1rem', fontSize: '1.1rem', fontWeight: 400, lineHeight: '1.5rem', opacity: 0.5, color: '#000', textAlign: 'center' }} onClick={(e) => {
                        e.preventDefault()
                        auth().signOut()
                    }}>{`Logout`}<br />{auth().currentUser.email}</Link>
                    : <Link style={{ paddingRight: '1rem', fontSize: '1.1rem', fontWeight: 400, lineHeight: '1.5rem', opacity: 0.5, color: '#000' }} to="/login">
                        {`Login or Sign Up`}
                    </Link>}
                </div>
            </div>
        </div>
        <div style={{ 
            marginTop: '2rem', 
            marginBottom: '4.5rem', 
            display: 'flex', 
            flexDirection: 'column', 
            width: '46rem', 
            alignItems: 'center', 
            justifyContent: 'left',
        }}>
            <div style={{ justifyContent: 'flex-start' }}>
                <h1 style={{ fontSize: '3.7rem', fontWeight: 600, lineHeight: 1.2, marginBottom: '1.5rem', textAlign: 'center' }}>404</h1>
            </div>
        </div>
    </div>
  );
}

export default FourOFour;