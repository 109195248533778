import React, { useEffect, useState, useContext } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory
  } from "react-router-dom";
import { ReactSVG } from 'react-svg'
import { auth, firestore } from "../../services/firebase";
import GlobalState from '../../context/GlobalState';
import context from '../../context/context';
import "fontsource-clear-sans"
import smalllogo from '../../smalllogo.svg';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function styles(width) {
    let fullPageWidth = "80rem"
    let headerFontSize = "3rem"
    let h1FontSize = '3.7rem'
    let orFontSize = '2.2rem'
    let pFontSize = '1.3rem'
    let bottomLinks = '1.1rem'
    let mobile = false
    let sectionMarginBottom = '4.5rem'
    let topLinks = '1.1rem'
    let paddingRight = '3rem'
    let lineHeight = '1.5rem'
    let mobileButton = '20%'
    let h2FontSize = '1.8rem'
    if (width >= 1300) {
        fullPageWidth = "46rem"
        headerFontSize = "3rem"
        pFontSize = '1.3rem'
        h1FontSize = '3.7rem'
        orFontSize = '2.2rem'
        h2FontSize = '1.8rem'
        mobileButton = '20%'
        mobile = false
    } else if (width < 1300 && width >= 760) {
        fullPageWidth = "46rem"
        headerFontSize = "3rem"
        pFontSize = '1.3rem'
        h1FontSize = '3.7rem'
        orFontSize = '2.2rem'
        h2FontSize = '1.8rem'
        mobileButton = '40%'
        mobile = false
    } else if (width < 760 ) {
        fullPageWidth = "20rem"
        headerFontSize = "1.75rem"
        pFontSize = '1rem'
        h2FontSize = '1.4rem'
        h1FontSize = '2.5rem'
        orFontSize = '1.5rem'
        mobileButton = '100%'
        mobile = true
        sectionMarginBottom  = '2rem'
        topLinks = '0.9rem'
        paddingRight = '1rem'
        lineHeight = '1.1rem'
    }
    return { fullPageWidth, h2FontSize, headerFontSize, pFontSize, mobile, sectionMarginBottom, h1FontSize, orFontSize, topLinks, paddingRight, lineHeight, mobileButton }
}

function WhyEng() {
    const history = useHistory();
    const { completed, setAllCompleted } = useContext(context);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
          setWindowDimensions(getWindowDimensions());
        }
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column',  width: windowDimensions.width-(styles(windowDimensions.width).mobile ? 8 : 64), flex: 1, alignItems: 'center', height: '100vh', fontFamily: "Clear Sans, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif", margin: 'auto' }}>
        <div style={{ 
            marginBottom: styles(windowDimensions.width).mobile ? '2rem' : '6rem',
            display: 'flex', 
            flexDirection: 'row', 
            borderBottomWidth: '0.225rem',
            borderBottomStyle: 'solid',
            borderBottomColor: 'rgb(236, 240, 241)',
            width: '100%'
        }}>
            <div style={{ display: 'flex', flex: 2, height: '4.5rem' }}>
                <Link to="/"> 
                    <ReactSVG beforeInjection={(svg) => {
                        svg.classList.add('svg-class-name')
                        svg.setAttribute('style', 'height: 4.5rem; width: 4rem; padding: 0.75rem 0 0.75rem 0.75rem')
                    }} src={smalllogo} style={{marginBottom: '0.75rem'}} />
                </Link>
            </div>
            <div style={{ display: 'flex', flex: 4, alignItems: 'center', justifyContent: 'flex-end' }}>
                <div style={{ marginRight: '-1rem', display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Link style={{ display: 'flex', paddingRight: styles(windowDimensions.width).paddingRight, fontSize: styles(windowDimensions.width).topLinks, fontWeight: 400, lineHeight: styles(windowDimensions.width).lineHeight, color: '#000' }} to="/questionlist">
                        Problem Index
                    </Link>
                    <Link style={{ display: 'flex', paddingRight: styles(windowDimensions.width).paddingRight, fontSize: styles(windowDimensions.width).topLinks, fontWeight: 400, lineHeight: styles(windowDimensions.width).lineHeight, opacity: 0.5, color: '#000' }} onClick={(e) => {
                        e.preventDefault();
                        const completedQuestions = Object.fromEntries(
                            Object.entries(completed).filter(([key, value]) => value === false) )
                        //console.log(Object.keys(completedQuestions).length*Math.random())
                        let randomIndex = 0
                        let linkTo = `/q/${Object.keys(completed)[randomIndex]}`
                        //console.log(Object.keys(completedQuestions).length)
                        if (Object.keys(completedQuestions).length*Math.random() === 0) {
                            randomIndex = Math.floor((Object.keys(completed).length)*Math.random())
                            linkTo = `/q/${Object.keys(completed)[randomIndex]}`
                        } else {
                            randomIndex = Math.floor((Object.keys(completedQuestions).length)*Math.random())
                            linkTo = `/q/${Object.keys(completedQuestions)[randomIndex]}`
                        }
                        
                        //console.log("linkTo", linkTo)
                        history.push(`${linkTo}`)
                    }}>
                        Problem Generator
                    </Link>
                    {auth().currentUser ? 
                    <Link style={{ display: 'flex', paddingRight: styles(windowDimensions.width).paddingRight, fontSize: styles(windowDimensions.width).topLinks, fontWeight: 400, lineHeight: styles(windowDimensions.width).lineHeight, opacity: 0.5, color: '#000' }} onClick={(e) => {
                        e.preventDefault()
                        auth().signOut()
                    }}>{`Logout`}<br />{auth().currentUser.email}</Link>
                    : <Link style={{ display: 'flex', paddingRight: styles(windowDimensions.width).paddingRight, fontSize: styles(windowDimensions.width).topLinks, fontWeight: 400, lineHeight: styles(windowDimensions.width).lineHeight, opacity: 0.5, color: '#000' }} to="/login">
                        {`Login or Sign Up`}
                    </Link>}
                </div>
            </div>
        </div>
        <div style={{ 
            marginBottom: '2rem', 
            paddingBottom: '0rem', 
            display: 'flex', 
            flexDirection: 'column', 
            width: styles(windowDimensions.width).fullPageWidth, 
            alignItems: 'center', 
            justifyContent: 'center',
            borderBottomWidth: '0.225rem',
            borderBottomStyle: 'solid',
            borderBottomColor: 'rgb(236, 240, 241)'
        }}>
            <h1 style={{ fontSize: styles(windowDimensions.width).h1FontSize, fontWeight: 600, lineHeight: '4.5rem', marginBottom: styles(windowDimensions.width).mobile ? '2rem' : '4.5rem', width: '100%', textAlign: 'center' }}>Why should I study engineering?</h1>
        </div>
        <div style={{ 
                marginBottom: styles(windowDimensions.width).sectionMarginBottom, 
                paddingBottom: '3rem', 
                display: 'flex', 
                flexDirection: 'column', 
                width: styles(windowDimensions.width).fullPageWidth, 
                alignItems: 'center', 
                justifyContent: 'center',
                borderBottomWidth: '0.225rem',
                borderBottomStyle: 'solid',
                borderBottomColor: 'rgb(236, 240, 241)'
            }}>
                <h2 style={{ fontSize: styles(windowDimensions.width).h2FontSize, fontWeight: 600, lineHeight: '2.25rem', marginBottom: '1.5rem' }}>Engineering is important. It is also challenging and exciting. Engineers use models provided by science combined with innovative thinking to solve problems and create new designs that benefit humanity.</h2>
                <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                    Albert Einstein said “Scientists investigate that which already is; Engineers create that which has never been ”. It is “Science ” to describe electromagnetic radiation, but it is “Engineering ” to build a radio or TV. Many of the so-called “wonders of modern Science ” are really wonders of modern Engineering. For example, it involves engineering to:
                </p>
                <ul style={{ padding: 0, margin: 0, width: styles(windowDimensions.width).fullPageWidth, marginBottom: '1.5rem' }}>
                    <li style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                        <p style={{ margin: 0, padding: 0 }}>protect the world’s natural resources and enable them to be used more efficiently</p>
                    </li>
                    <li style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize  }}>
                        <p style={{ margin: 0, padding: 0 }}>invent new types of diagnostic medical scanners</p>
                    </li>
                    <li style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize  }}>
                        <p style={{ margin: 0, padding: 0 }}>design and build safer, faster, quieter, more fuel-efficient aircraft</p>
                    </li>
                    <li style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize  }}>
                        <p style={{ margin: 0, padding: 0 }}>create smaller smartphones that are easier to use</p>
                    </li>
                    <li style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize  }}>
                        <p style={{ margin: 0, padding: 0 }}>synthesise biologically compatible materials for artificial limbs</p>
                    </li>
                </ul>
                <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                    Many people are educated in engineering skills and use them in their jobs, but few are actually called “engineers ”. The vital importance of engineering is thus sometimes difficult to see.
                </p>
                <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                    A career in engineering is interesting and fun. It involves a lifetime of continuous learning to adapt to changes in society and the natural world. It often involves working in multi-disciplinary, multi-cultural, multi-site teams. It is a very worthwhile profession, and the results, when you succeed, can be incredibly satisfying.
                </p>
                <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                    Resources are provided by the following organisations:
                </p>
                <ul style={{ padding: 0 }}>
                    <li style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                        <a href="http://engineergirl.org/"
                        style={{ color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db' }}>Engineer Girl</a>
                    </li>
                    <li style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                        <a href="http://www.smallpeicetrust.org.uk/"
                        style={{ color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db' }}>Smallpiece Trust</a>
                    </li>
                    <li style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                        <a href="http://www.jamesdysonfoundation.co.uk"
                        style={{ color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db' }}>James Dyson Foundation</a>
                    </li>
                    <li style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                        <a href="https://eandt.theiet.org/"
                        style={{ color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db' }}>Institution of Engineering and Technology</a>
                    </li>
                    <li style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                        <a href="https://www.ice.org.uk/what-is-civil-engineering"
                        style={{ color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db' }}>Institution of Civil Engineers</a>
                    </li>
                    <li style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                        <a href="https://www.istructe.org/resources/teaching-resources/teaching-resources-for-schools/"
                        style={{ color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db' }}>Institution of Structural Engineers</a>
                    </li>
                    <li style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                        <a href="http://www.robogals.org"
                        style={{ color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db' }}>Robogals</a>
                    </li>
                    <li style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                        <a href="https://raeng.org.uk/education-and-skills/this-is-engineering"
                        style={{ color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db' }}>Royal Academy of Engineering</a>
                    </li>
                </ul>
                <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                    Need more advice? Read our list of <Link to="/choosesubjects" style={{ color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db' }}>“Which subjects should I study”</Link>.
                </p>
        </div>
        <div style={{ 
            marginBottom: '4.5rem', 
            paddingBottom: '12rem', 
            display: 'flex', 
            flexDirection: 'row', 
            width: styles(windowDimensions.width).fullPageWidth,
            alignItems: 'center', 
            justifyContent: 'center',
        }}> 
            <span style={{ marginTop: '1.5rem' }}>
                <Link to="/terms_of_use" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingRight: 5 }}>Terms of use</Link> · 
                <Link to="/privacy_policy" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingRight: 5, paddingLeft: 5 }}>Privacy Policy</Link> · 
                <Link to="/cookies_policy" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingRight: 5, paddingLeft: 5 }}>Cookies Policy</Link> · 
                <Link to="/system_requirements" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingRight: 5, paddingLeft: 5 }}>System Requirements</Link> · 
                {/*<Link to="/faq" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingRight: 5, paddingLeft: 5 }}>FAQ</Link> · */}
                <Link to="/credits" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingLeft: 5 }}>Credits</Link>
            </span>
        </div>
    </div>
  );
}

export default WhyEng;