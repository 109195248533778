import React, { useEffect, useState, useContext } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory
  } from "react-router-dom";
import { ReactSVG } from 'react-svg'
import { auth, firestore } from "../../services/firebase";
import GlobalState from '../../context/GlobalState';
import context from '../../context/context';
import "fontsource-clear-sans"
import smalllogo from '../../smalllogo.svg';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function styles(width) {
    let fullPageWidth = "80rem"
    let headerFontSize = "3rem"
    let h1FontSize = '3.7rem'
    let orFontSize = '2.2rem'
    let pFontSize = '1.3rem'
    let bottomLinks = '1.1rem'
    let mobile = false
    let sectionMarginBottom = '4.5rem'
    let topLinks = '1.1rem'
    let paddingRight = '3rem'
    let lineHeight = '1.5rem'
    let mobileButton = '20%'
    let h2FontSize = '1.8rem'
    if (width >= 1300) {
        fullPageWidth = "46rem"
        headerFontSize = "3rem"
        pFontSize = '1.3rem'
        h1FontSize = '3.7rem'
        orFontSize = '2.2rem'
        h2FontSize = '1.8rem'
        mobileButton = '20%'
        mobile = false
    } else if (width < 1300 && width >= 760) {
        fullPageWidth = "46rem"
        headerFontSize = "3rem"
        pFontSize = '1.3rem'
        h1FontSize = '3.7rem'
        orFontSize = '2.2rem'
        h2FontSize = '1.8rem'
        mobileButton = '40%'
        mobile = false
    } else if (width < 760 ) {
        fullPageWidth = "20rem"
        headerFontSize = "1.75rem"
        pFontSize = '1rem'
        h2FontSize = '1.4rem'
        h1FontSize = '2.5rem'
        orFontSize = '1.5rem'
        mobileButton = '100%'
        mobile = true
        sectionMarginBottom  = '2rem'
        topLinks = '0.9rem'
        paddingRight = '1rem'
        lineHeight = '1.1rem'
    }
    return { fullPageWidth, h2FontSize, headerFontSize, pFontSize, mobile, sectionMarginBottom, h1FontSize, orFontSize, topLinks, paddingRight, lineHeight, mobileButton }
}

function ChooseSubjects() {
    const history = useHistory();
    const { completed, setAllCompleted } = useContext(context);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
          setWindowDimensions(getWindowDimensions());
        }
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column',  width: windowDimensions.width-(styles(windowDimensions.width).mobile ? 8 : 64), flex: 1, alignItems: 'center', height: '100vh', fontFamily: "Clear Sans, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif", margin: 'auto' }}>
        <div style={{ 
            marginBottom: styles(windowDimensions.width).mobile ? '2rem' : '6rem',
            display: 'flex', 
            flexDirection: 'row', 
            borderBottomWidth: '0.225rem',
            borderBottomStyle: 'solid',
            borderBottomColor: 'rgb(236, 240, 241)',
            width: '100%'
        }}>
            <div style={{ display: 'flex', flex: 2, height: '4.5rem' }}>
                <Link to="/"> 
                    <ReactSVG beforeInjection={(svg) => {
                        svg.classList.add('svg-class-name')
                        svg.setAttribute('style', 'height: 4.5rem; width: 4rem; padding: 0.75rem 0 0.75rem 0.75rem')
                    }} src={smalllogo} style={{marginBottom: '0.75rem'}} />
                </Link>
            </div>
            <div style={{ display: 'flex', flex: 4, alignItems: 'center', justifyContent: 'flex-end' }}>
                <div style={{ marginRight: '-1rem', display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Link style={{ display: 'flex', paddingRight: styles(windowDimensions.width).paddingRight, fontSize: styles(windowDimensions.width).topLinks, fontWeight: 400, lineHeight: styles(windowDimensions.width).lineHeight, color: '#000' }} to="/questionlist">
                        Problem Index
                    </Link>
                    <Link style={{ display: 'flex', paddingRight: styles(windowDimensions.width).paddingRight, fontSize: styles(windowDimensions.width).topLinks, fontWeight: 400, lineHeight: styles(windowDimensions.width).lineHeight, opacity: 0.5, color: '#000' }} onClick={(e) => {
                        e.preventDefault();
                        const completedQuestions = Object.fromEntries(
                            Object.entries(completed).filter(([key, value]) => value === false) )
                        //console.log(Object.keys(completedQuestions).length*Math.random())
                        let randomIndex = 0
                        let linkTo = `/q/${Object.keys(completed)[randomIndex]}`
                        //console.log(Object.keys(completedQuestions).length)
                        if (Object.keys(completedQuestions).length*Math.random() === 0) {
                            randomIndex = Math.floor((Object.keys(completed).length)*Math.random())
                            linkTo = `/q/${Object.keys(completed)[randomIndex]}`
                        } else {
                            randomIndex = Math.floor((Object.keys(completedQuestions).length)*Math.random())
                            linkTo = `/q/${Object.keys(completedQuestions)[randomIndex]}`
                        }
                        
                        //console.log("linkTo", linkTo)
                        history.push(`${linkTo}`)
                    }}>
                        Problem Generator
                    </Link>
                    {auth().currentUser ? 
                    <Link style={{ display: 'flex', paddingRight: styles(windowDimensions.width).paddingRight, fontSize: styles(windowDimensions.width).topLinks, fontWeight: 400, lineHeight: styles(windowDimensions.width).lineHeight, opacity: 0.5, color: '#000' }} onClick={(e) => {
                        e.preventDefault()
                        auth().signOut()
                    }}>{`Logout`}<br />{auth().currentUser.email}</Link>
                    : <Link style={{ display: 'flex', paddingRight: styles(windowDimensions.width).paddingRight, fontSize: styles(windowDimensions.width).topLinks, fontWeight: 400, lineHeight: styles(windowDimensions.width).lineHeight, opacity: 0.5, color: '#000' }} to="/login">
                        {`Login or Sign Up`}
                    </Link>}
                </div>
            </div>
        </div>
        <div style={{ 
            marginBottom: '2rem', 
            paddingBottom: '0rem', 
            display: 'flex', 
            flexDirection: 'column', 
            width: styles(windowDimensions.width).fullPageWidth, 
            alignItems: 'center', 
            justifyContent: 'center',
            borderBottomWidth: '0.225rem',
            borderBottomStyle: 'solid',
            borderBottomColor: 'rgb(236, 240, 241)'
        }}>
            <h1 style={{ fontSize: styles(windowDimensions.width).h1FontSize, fontWeight: 600, lineHeight: '4.5rem', marginBottom: styles(windowDimensions.width).mobile ? '2rem' : '4.5rem', width: '100%', textAlign: 'center' }}>Which subjects should I study?</h1>
        </div>
        <div style={{ 
                marginBottom: styles(windowDimensions.width).sectionMarginBottom, 
                paddingBottom: '3rem', 
                display: 'flex', 
                flexDirection: 'column', 
                width: styles(windowDimensions.width).fullPageWidth, 
                alignItems: 'center', 
                justifyContent: 'center',
                borderBottomWidth: '0.225rem',
                borderBottomStyle: 'solid',
                borderBottomColor: 'rgb(236, 240, 241)'
            }}>
                <h2 style={{ fontSize: styles(windowDimensions.width).h2FontSize, fontWeight: 600, lineHeight: '2.25rem', marginBottom: '1.5rem' }}>The safest way to be sure you are studying for the right exams to get onto a particular university course is to find out directly which subjects that particular university recommends. This information is often available on their website. If not then you can contact the university admissions office. If you are thinking of applying to Cambridge or Oxford, then contact the College that you expect to apply to. The important thing is to get direct advice before you choose the subjects that you will study for A-levels, Scottish Higher, IB, or other exams.</h2>
                <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                    Having said this, the main background material required for most engineering courses is mathematics and physics. If at all possible, take something equivalent to Maths + Further Maths A2 at A-level. Take Physics, and if you are interested in Chemical Engineering, take Chemistry. Beyond these, it is more difficult to give advice. If you are taking A-levels, it is generally better from a university admissions point of view to get good results (at least grade A) in Maths, Further Maths and Physics than to take more subjects.
                </p>
                <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                    The <a href="https://nrich.maths.org/6298"
                    style={{ color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db' }}>Millenium Mathematics Project</a> provides excellent online resources covering more than just maths. <a href="https://isaacphysics.org/"
                    style={{ color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db' }}>Isaac Physics</a> provides an exciting range of problems and exercises. The <a href="https://myheplus.com/subject/engineering"
                    style={{ color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db' }}>HE+</a> project offers a wide variety of help in developing academic skill in preparation for university entrance.
                </p>
                <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                    Need more advice? Read <Link to="/experience" style={{ color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db' }}>how you can get a place on an engineering degree</Link>.
                </p>
        </div>
        <div style={{ 
            marginBottom: '4.5rem', 
            paddingBottom: '12rem', 
            display: 'flex', 
            flexDirection: 'row', 
            width: styles(windowDimensions.width).fullPageWidth,
            alignItems: 'center', 
            justifyContent: 'center',
        }}> 
            <span style={{ marginTop: '1.5rem' }}>
                <Link to="/terms_of_use" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingRight: 5 }}>Terms of use</Link> · 
                <Link to="/privacy_policy" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingRight: 5, paddingLeft: 5 }}>Privacy Policy</Link> · 
                <Link to="/cookies_policy" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingRight: 5, paddingLeft: 5 }}>Cookies Policy</Link> · 
                <Link to="/system_requirements" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingRight: 5, paddingLeft: 5 }}>System Requirements</Link> · 
                {/*<Link to="/faq" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingRight: 5, paddingLeft: 5 }}>FAQ</Link> · */}
                <Link to="/credits" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingLeft: 5 }}>Credits</Link>
            </span>
        </div>
    </div>
  );
}

export default ChooseSubjects;