import React, { useEffect, useState, useContext } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory
  } from "react-router-dom";
import { ReactSVG } from 'react-svg'
import { auth, firestore } from "../../services/firebase";
import GlobalState from '../../context/GlobalState';
import context from '../../context/context';
import "fontsource-clear-sans"
import smalllogo from '../../smalllogo.svg';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function styles(width) {
    let fullPageWidth = "80rem"
    let headerFontSize = "3rem"
    let h1FontSize = '3.7rem'
    let orFontSize = '2.2rem'
    let pFontSize = '1.3rem'
    let bottomLinks = '1.1rem'
    let mobile = false
    let sectionMarginBottom = '4.5rem'
    let topLinks = '1.1rem'
    let paddingRight = '3rem'
    let lineHeight = '1.5rem'
    let mobileButton = '20%'
    if (width >= 1300) {
        fullPageWidth = "46rem"
        headerFontSize = "3rem"
        pFontSize = '1.3rem'
        h1FontSize = '3.7rem'
        orFontSize = '2.2rem'
        mobileButton = '20%'
        mobile = false
    } else if (width < 1300 && width >= 760) {
        fullPageWidth = "46rem"
        headerFontSize = "3rem"
        pFontSize = '1.3rem'
        h1FontSize = '3.7rem'
        orFontSize = '2.2rem'
        mobileButton = '40%'
        mobile = false
    } else if (width < 760 ) {
        fullPageWidth = "20rem"
        headerFontSize = "1.75rem"
        pFontSize = '1rem'
        h1FontSize = '2.5rem'
        orFontSize = '1.5rem'
        mobileButton = '100%'
        mobile = true
        sectionMarginBottom  = '2rem'
        topLinks = '0.9rem'
        paddingRight = '1rem'
        lineHeight = '1.1rem'
    }
    return { fullPageWidth, headerFontSize, pFontSize, mobile, sectionMarginBottom, h1FontSize, orFontSize, topLinks, paddingRight, lineHeight, mobileButton }
}

function Credits() {
    const history = useHistory();
    const { completed, setAllCompleted } = useContext(context);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
          setWindowDimensions(getWindowDimensions());
        }
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column',  width: windowDimensions.width-(styles(windowDimensions.width).mobile ? 8 : 64), flex: 1, alignItems: 'center', height: '100vh', fontFamily: "Clear Sans, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif", margin: 'auto' }}>
        <div style={{ 
            marginBottom: styles(windowDimensions.width).mobile ? '2rem' : '6rem',
            display: 'flex', 
            flexDirection: 'row', 
            borderBottomWidth: '0.225rem',
            borderBottomStyle: 'solid',
            borderBottomColor: 'rgb(236, 240, 241)',
            width: '100%'
        }}>
            <div style={{ display: 'flex', flex: 2, height: '4.5rem' }}>
                <Link to="/"> 
                    <ReactSVG beforeInjection={(svg) => {
                        svg.classList.add('svg-class-name')
                        svg.setAttribute('style', 'height: 4.5rem; width: 4rem; padding: 0.75rem 0 0.75rem 0.75rem')
                    }} src={smalllogo} style={{marginBottom: '0.75rem'}} />
                </Link>
            </div>
            <div style={{ display: 'flex', flex: 4, alignItems: 'center', justifyContent: 'flex-end' }}>
                <div style={{ marginRight: '-1rem', display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Link style={{ display: 'flex', paddingRight: styles(windowDimensions.width).paddingRight, fontSize: styles(windowDimensions.width).topLinks, fontWeight: 400, lineHeight: styles(windowDimensions.width).lineHeight, color: '#000' }} to="/questionlist">
                        Problem Index
                    </Link>
                    <Link style={{ display: 'flex', paddingRight: styles(windowDimensions.width).paddingRight, fontSize: styles(windowDimensions.width).topLinks, fontWeight: 400, lineHeight: styles(windowDimensions.width).lineHeight, opacity: 0.5, color: '#000' }} onClick={(e) => {
                        e.preventDefault();
                        const completedQuestions = Object.fromEntries(
                            Object.entries(completed).filter(([key, value]) => value === false) )
                        //console.log(Object.keys(completedQuestions).length*Math.random())
                        let randomIndex = 0
                        let linkTo = `/q/${Object.keys(completed)[randomIndex]}`
                        //console.log(Object.keys(completedQuestions).length)
                        if (Object.keys(completedQuestions).length*Math.random() === 0) {
                            randomIndex = Math.floor((Object.keys(completed).length)*Math.random())
                            linkTo = `/q/${Object.keys(completed)[randomIndex]}`
                        } else {
                            randomIndex = Math.floor((Object.keys(completedQuestions).length)*Math.random())
                            linkTo = `/q/${Object.keys(completedQuestions)[randomIndex]}`
                        }
                        
                        //console.log("linkTo", linkTo)
                        history.push(`${linkTo}`)
                    }}>
                        Problem Generator
                    </Link>
                    {auth().currentUser ? 
                    <Link style={{ display: 'flex', paddingRight: styles(windowDimensions.width).paddingRight, fontSize: styles(windowDimensions.width).topLinks, fontWeight: 400, lineHeight: styles(windowDimensions.width).lineHeight, opacity: 0.5, color: '#000' }} onClick={(e) => {
                        e.preventDefault()
                        auth().signOut()
                    }}>{`Logout`}<br />{auth().currentUser.email}</Link>
                    : <Link style={{ display: 'flex', paddingRight: styles(windowDimensions.width).paddingRight, fontSize: styles(windowDimensions.width).topLinks, fontWeight: 400, lineHeight: styles(windowDimensions.width).lineHeight, opacity: 0.5, color: '#000' }} to="/login">
                        {`Login or Sign Up`}
                    </Link>}
                </div>
            </div>
        </div>
        <div style={{ 
            marginBottom: '2rem', 
            paddingBottom: '0rem', 
            display: 'flex', 
            flexDirection: 'column', 
            width: styles(windowDimensions.width).fullPageWidth, 
            alignItems: 'center', 
            justifyContent: 'center',
            borderBottomWidth: '0.225rem',
            borderBottomStyle: 'solid',
            borderBottomColor: 'rgb(236, 240, 241)'
        }}>
            <h1 style={{ fontSize: styles(windowDimensions.width).h1FontSize, fontWeight: 600, lineHeight: '4.5rem', marginBottom: styles(windowDimensions.width).mobile ? '2rem' : '4.5rem', width: '100%', textAlign: 'center' }}>Credits</h1>
        </div>
        <div style={{ 
                marginBottom: styles(windowDimensions.width).sectionMarginBottom, 
                paddingBottom: '3rem', 
                display: 'flex', 
                flexDirection: 'column', 
                width: styles(windowDimensions.width).fullPageWidth, 
                alignItems: 'center', 
                justifyContent: 'center',
                borderBottomWidth: '0.225rem',
                borderBottomStyle: 'solid',
                borderBottomColor: 'rgb(236, 240, 241)'
            }}>
            <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
            This website is edited by Richard Prager. Russell Hunter created the technical implementation based on an original framework by Alastair Beresford and Andrew Rice. The design is by Max Alfie Murdoch. Je Hyeong (John) Hong, Zerline Lim, Alice Brown, Daniel Scott, Abigail Bankay, Hansley Narasiah, Seema Kapacee and Esther Sidebotham contributed significantly to the content and testing.    
            </p>
        </div>
        <div style={{ 
                marginBottom: styles(windowDimensions.width).sectionMarginBottom, 
                paddingBottom: '3rem', 
                display: 'flex', 
                flexDirection: 'column', 
                width: styles(windowDimensions.width).fullPageWidth, 
                alignItems: 'center', 
                justifyContent: 'center',
                borderBottomWidth: '0.225rem',
                borderBottomStyle: 'solid',
                borderBottomColor: 'rgb(236, 240, 241)'
            }}>
            <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
                <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
                    <div style={{ display: "flex", flex: 1 }}>
                        <div>
                            <img style={{ backgroundColor: 'white', marginLeft: 'auto', marginRight: 'auto', padding: '0.75rem', marginBottom: '1.5rem', borderRadius: '0.3rem', maxWidth: '100%' }} src={'https://firebasestorage.googleapis.com/v0/b/iwtse-1928f.appspot.com/o/richard-prager.jpeg?alt=media&token=db872414-3774-4d1c-99bf-5dae2925cacf'} />
                            <p style={{ fontSize: '1.1rem' }}>Richard Prager</p>
                        </div>
                    </div>
                    <div style={{ display: "flex", flex: 1 }}>
                        <div>
                            <img style={{ backgroundColor: 'white', marginLeft: 'auto', marginRight: 'auto', padding: '0.75rem', marginBottom: '1.5rem', borderRadius: '0.3rem', maxWidth: '100%' }} src={'https://firebasestorage.googleapis.com/v0/b/iwtse-1928f.appspot.com/o/russell_hunter.jpeg?alt=media&token=49e371dc-0f0d-49fd-9d8e-5da1e3cf5a29'} />
                            <p style={{ fontSize: '1.1rem' }}>Russell Hunter</p>
                        </div>
                    </div>
                    <div style={{ display: "flex", flex: 1 }}>
                        <div>
                            <img style={{ backgroundColor: 'white', marginLeft: 'auto', marginRight: 'auto', padding: '0.75rem', marginBottom: '1.5rem', borderRadius: '0.3rem', maxWidth: '100%' }} src={'https://firebasestorage.googleapis.com/v0/b/iwtse-1928f.appspot.com/o/je_hyeong_hong.jpeg?alt=media&token=23b7b60b-11c4-4f31-88f8-88483c7f5c5f'} />
                            <p style={{ fontSize: '1.1rem' }}>Je Hyeong Hong</p>
                        </div>
                    </div>
                </div>
                <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
                    <div style={{ display: "flex", flex: 1 }}>
                        <div>
                            <img style={{ backgroundColor: 'white', marginLeft: 'auto', marginRight: 'auto', padding: '0.75rem', marginBottom: '1.5rem', borderRadius: '0.3rem', maxWidth: '100%' }} src={'https://firebasestorage.googleapis.com/v0/b/iwtse-1928f.appspot.com/o/zerline_lim.jpeg?alt=media&token=55bf9d60-ec8b-4b37-9bae-1dbbae0a3644'} />
                            <p style={{ fontSize: '1.1rem' }}>Zerline Lim</p>
                        </div>
                    </div>
                    <div style={{ display: "flex", flex: 1 }}>
                        <div>
                            <img style={{ backgroundColor: 'white', marginLeft: 'auto', marginRight: 'auto', padding: '0.75rem', marginBottom: '1.5rem', borderRadius: '0.3rem', maxWidth: '100%' }} src={'https://firebasestorage.googleapis.com/v0/b/iwtse-1928f.appspot.com/o/alice_brown.jpeg?alt=media&token=cf5e85bf-5ab7-457e-a1f6-7183a303a063'} />
                            <p style={{ fontSize: '1.1rem' }}>Alice Brown</p>
                        </div>
                    </div>
                    <div style={{ display: "flex", flex: 1 }}>
                        <div>
                            <img style={{ backgroundColor: 'white', marginLeft: 'auto', marginRight: 'auto', padding: '0.75rem', marginBottom: '1.5rem', borderRadius: '0.3rem', maxWidth: '100%' }} src={'https://firebasestorage.googleapis.com/v0/b/iwtse-1928f.appspot.com/o/daniel_scott.jpeg?alt=media&token=e3dda185-a96c-4b71-a109-91a07f4ef049'} />
                            <p style={{ fontSize: '1.1rem' }}>Daniel Scott</p>
                        </div>
                    </div>
                </div>
                <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
                    <div style={{ display: "flex", flex: 1 }}>
                        <div>
                            <img style={{ backgroundColor: 'white', marginLeft: 'auto', marginRight: 'auto', padding: '0.75rem', marginBottom: '1.5rem', borderRadius: '0.3rem', maxWidth: '100%' }} src={'https://firebasestorage.googleapis.com/v0/b/iwtse-1928f.appspot.com/o/abigail_bankay.jpeg?alt=media&token=db24ebe1-ec87-48b1-983a-37a77dd599ee'} />
                            <p style={{ fontSize: '1.1rem' }}>Abigail Bankay</p>
                        </div>
                    </div>
                    <div style={{ display: "flex", flex: 1 }}>
                        <div>
                            <img style={{ backgroundColor: 'white', marginLeft: 'auto', marginRight: 'auto', padding: '0.75rem', marginBottom: '1.5rem', borderRadius: '0.3rem', maxWidth: '100%' }} src={'https://firebasestorage.googleapis.com/v0/b/iwtse-1928f.appspot.com/o/hansley_narasiah.jpeg?alt=media&token=903c0c20-4bbb-4019-80ab-8dcf9b771dfd'} />
                            <p style={{ fontSize: '1.1rem' }}>Hansley Narasiah</p>
                        </div>
                    </div>
                    <div style={{ display: "flex", flex: 1 }}>
                        <div>
                            <img style={{ backgroundColor: 'white', marginLeft: 'auto', marginRight: 'auto', padding: '0.75rem', marginBottom: '1.5rem', borderRadius: '0.3rem', maxWidth: '100%' }} src={'https://firebasestorage.googleapis.com/v0/b/iwtse-1928f.appspot.com/o/esther_sidebotham.jpeg?alt=media&token=77298e80-63ef-40be-a3a1-2b95c9a6b887'} />
                            <p style={{ fontSize: '1.1rem' }}>Esther Sidebotham</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style={{ 
                marginBottom: styles(windowDimensions.width).sectionMarginBottom, 
                paddingBottom: '3rem', 
                display: 'flex', 
                flexDirection: 'column', 
                width: styles(windowDimensions.width).fullPageWidth, 
                alignItems: 'center', 
                justifyContent: 'center',
            }}>
            <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
            We are most grateful to The Underwood Trust for funding the project to create this website. The project is hosted and supported by Cambridge University Engineering Department and Cambridge University Computer Laboratory.   
            </p>
            <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                We are grateful to Mark Warner and the <a  href="https://isaacphysics.org/"
                style={{ color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db' }}>Isaac Physics Project</a> for helpful suggestions and advice, and to Tom May for thoughtful guidance, encouragement and support. We would like to thank <a href="https://www.liverpool.ac.uk/engineering/staff/peter-goodhew/"
                style={{ color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db' }}>Peter Goodhew</a> for permission to use ideas from the “concept questions ” assembled at the 2011 CDIO conference in Copenhagen.   
            </p>
        </div>
        {false && <div style={{ 
            marginTop: '2rem', 
            marginBottom: '4.5rem', 
            display: 'flex', 
            flexDirection: 'column', 
            width: '46rem', 
            alignItems: 'center', 
            justifyContent: 'left',
        }}>
            <div style={{ justifyContent: 'flex-start' }}>
                <h1 style={{ fontSize: '3.7rem', fontWeight: 600, lineHeight: 1.2, marginBottom: '1.5rem', textAlign: 'center' }}>Credits</h1>
                <p style={{ textAlign: 'left', fontSize: '1.3rem' }}>
                This website is edited by Richard Prager. Russell Hunter created the technical implementation based on an original framework by Alastair Beresford and Andrew Rice. The design is by Max Alfie Murdoch. Je Hyeong (John) Hong, Zerline Lim, Alice Brown, Daniel Scott, Abigail Bankay, Hansley Narasiah, Seema Kapacee and Esther Sidebotham contributed significantly to the content and testing.
                </p>
                <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
                    <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
                        <div style={{ display: "flex", flex: 1 }}>
                            <div>
                                <img style={{ backgroundColor: 'white', marginLeft: 'auto', marginRight: 'auto', padding: '0.75rem', marginBottom: '1.5rem', borderRadius: '0.3rem', maxWidth: '100%' }} src={'https://firebasestorage.googleapis.com/v0/b/iwtse-1928f.appspot.com/o/richard-prager.jpeg?alt=media&token=db872414-3774-4d1c-99bf-5dae2925cacf'} />
                                <p style={{ fontSize: '1.1rem' }}>Richard Prager</p>
                            </div>
                        </div>
                        <div style={{ display: "flex", flex: 1 }}>
                            <div>
                                <img style={{ backgroundColor: 'white', marginLeft: 'auto', marginRight: 'auto', padding: '0.75rem', marginBottom: '1.5rem', borderRadius: '0.3rem', maxWidth: '100%' }} src={'https://firebasestorage.googleapis.com/v0/b/iwtse-1928f.appspot.com/o/russell_hunter.jpeg?alt=media&token=49e371dc-0f0d-49fd-9d8e-5da1e3cf5a29'} />
                                <p style={{ fontSize: '1.1rem' }}>Russell Hunter</p>
                            </div>
                        </div>
                        <div style={{ display: "flex", flex: 1 }}>
                            <div>
                                <img style={{ backgroundColor: 'white', marginLeft: 'auto', marginRight: 'auto', padding: '0.75rem', marginBottom: '1.5rem', borderRadius: '0.3rem', maxWidth: '100%' }} src={'https://firebasestorage.googleapis.com/v0/b/iwtse-1928f.appspot.com/o/je_hyeong_hong.jpeg?alt=media&token=23b7b60b-11c4-4f31-88f8-88483c7f5c5f'} />
                                <p style={{ fontSize: '1.1rem' }}>Je Hyeong Hong</p>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
                        <div style={{ display: "flex", flex: 1 }}>
                            <div>
                                <img style={{ backgroundColor: 'white', marginLeft: 'auto', marginRight: 'auto', padding: '0.75rem', marginBottom: '1.5rem', borderRadius: '0.3rem', maxWidth: '100%' }} src={'https://firebasestorage.googleapis.com/v0/b/iwtse-1928f.appspot.com/o/zerline_lim.jpeg?alt=media&token=55bf9d60-ec8b-4b37-9bae-1dbbae0a3644'} />
                                <p style={{ fontSize: '1.1rem' }}>Zerline Lim</p>
                            </div>
                        </div>
                        <div style={{ display: "flex", flex: 1 }}>
                            <div>
                                <img style={{ backgroundColor: 'white', marginLeft: 'auto', marginRight: 'auto', padding: '0.75rem', marginBottom: '1.5rem', borderRadius: '0.3rem', maxWidth: '100%' }} src={'https://firebasestorage.googleapis.com/v0/b/iwtse-1928f.appspot.com/o/alice_brown.jpeg?alt=media&token=cf5e85bf-5ab7-457e-a1f6-7183a303a063'} />
                                <p style={{ fontSize: '1.1rem' }}>Alice Brown</p>
                            </div>
                        </div>
                        <div style={{ display: "flex", flex: 1 }}>
                            <div>
                                <img style={{ backgroundColor: 'white', marginLeft: 'auto', marginRight: 'auto', padding: '0.75rem', marginBottom: '1.5rem', borderRadius: '0.3rem', maxWidth: '100%' }} src={'https://firebasestorage.googleapis.com/v0/b/iwtse-1928f.appspot.com/o/daniel_scott.jpeg?alt=media&token=e3dda185-a96c-4b71-a109-91a07f4ef049'} />
                                <p style={{ fontSize: '1.1rem' }}>Daniel Scott</p>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
                        <div style={{ display: "flex", flex: 1 }}>
                            <div>
                                <img style={{ backgroundColor: 'white', marginLeft: 'auto', marginRight: 'auto', padding: '0.75rem', marginBottom: '1.5rem', borderRadius: '0.3rem', maxWidth: '100%' }} src={'https://firebasestorage.googleapis.com/v0/b/iwtse-1928f.appspot.com/o/abigail_bankay.jpeg?alt=media&token=db24ebe1-ec87-48b1-983a-37a77dd599ee'} />
                                <p style={{ fontSize: '1.1rem' }}>Abigail Bankay</p>
                            </div>
                        </div>
                        <div style={{ display: "flex", flex: 1 }}>
                            <div>
                                <img style={{ backgroundColor: 'white', marginLeft: 'auto', marginRight: 'auto', padding: '0.75rem', marginBottom: '1.5rem', borderRadius: '0.3rem', maxWidth: '100%' }} src={'https://firebasestorage.googleapis.com/v0/b/iwtse-1928f.appspot.com/o/hansley_narasiah.jpeg?alt=media&token=903c0c20-4bbb-4019-80ab-8dcf9b771dfd'} />
                                <p style={{ fontSize: '1.1rem' }}>Hansley Narasiah</p>
                            </div>
                        </div>
                        <div style={{ display: "flex", flex: 1 }}>
                            <div>
                                <img style={{ backgroundColor: 'white', marginLeft: 'auto', marginRight: 'auto', padding: '0.75rem', marginBottom: '1.5rem', borderRadius: '0.3rem', maxWidth: '100%' }} src={'https://firebasestorage.googleapis.com/v0/b/iwtse-1928f.appspot.com/o/esther_sidebotham.jpeg?alt=media&token=77298e80-63ef-40be-a3a1-2b95c9a6b887'} />
                                <p style={{ fontSize: '1.1rem' }}>Esther Sidebotham</p>
                            </div>
                        </div>
                    </div>
                </div>
                <p style={{ textAlign: 'left', fontSize: '1.3rem' }}>
                We are most grateful to The Underwood Trust for funding the project to create this website. The project is hosted and supported by Cambridge University Engineering Department and Cambridge University Computer Laboratory.   
                </p>
                <p style={{ textAlign: 'left', fontSize: '1.3rem' }}>
                We are grateful to Mark Warner and the <a  href="https://isaacphysics.org/"
                     style={{ color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db' }}>Isaac Physics Project</a> for helpful suggestions and advice, and to Tom May for thoughtful guidance, encouragement and support. We would like to thank <a href="https://www.liverpool.ac.uk/engineering/staff/peter-goodhew/"
                     style={{ color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db' }}>Peter Goodhew</a> for permission to use ideas from the “concept questions ” assembled at the 2011 CDIO conference in Copenhagen.   
                </p>
            </div>
        </div>}
        <div style={{ 
            marginBottom: '4.5rem', 
            paddingBottom: '12rem', 
            display: 'flex', 
            flexDirection: 'row', 
            width: styles(windowDimensions.width).fullPageWidth,
            alignItems: 'center', 
            justifyContent: 'center',
            borderTopWidth: '0.225rem',
            borderTopStyle: 'solid',
            borderTopColor: 'rgb(236, 240, 241)'
        }}> 
            <span style={{ marginTop: '1.5rem' }}>
                <Link to="/terms_of_use" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingRight: 5 }}>Terms of use</Link> · 
                <Link to="/privacy_policy" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingRight: 5, paddingLeft: 5 }}>Privacy Policy</Link> · 
                <Link to="/cookies_policy" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingRight: 5, paddingLeft: 5 }}>Cookies Policy</Link> · 
                <Link to="/system_requirements" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingRight: 5, paddingLeft: 5 }}>System Requirements</Link> · 
                {/*<Link to="/faq" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingRight: 5, paddingLeft: 5 }}>FAQ</Link> · */}
                <Link to="/credits" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingLeft: 5 }}>Credits</Link>
            </span>
        </div>
    </div>
  );
}

export default Credits;