import React from 'react';

export default React.createContext({
    completed: {
        "_2nd_order_differential_equation":false,
        "aircraft_cornering":false,
        "aircraft_drink":false,
        "angle_between_vectors":false,
        "angle_between_vectors_3d":false,
        "arc_of_a_circle":false,
        "arcs_inside_a_square":false,
        "area_of_ellipse":false,
        "bacteria":false,
        "balances":false,
        "ball_in_oil":false,
        "balloon_in_truck":false,
        "balls_in_a_bag":false,
        "beakers":false,
        "bicycle_turning":false,
        "block_on_slope":false,
        "block_on_spring":false,
        "bouncy_ball":false,
        "bowl_of_sweets":false,
        "bungee_bounce_frequency":false,
        "bungee_jumper":false,
        "capacitor_switch":false,
        "capacitors_and_diodes":false,
        "cards_and_logic":false,
        "chain_rule":false,
        "charges_and_masses":false,
        "children":false,
        "circle_intersection":false,
        "circle_series_1":false,
        "circle_series_2":false,
        "clock":false,
        "coconuts":false,
        "cogs":false,
        "coin_flipping":false,
        "coin_order":false,
        "combining_capacitors":false,
        "complex_power":false,
        "complex_roots":false,
        "complex_square":false,
        "conical_pendulum":false,
        "construction_worker_1":false,
        "construction_worker_2":false,
        "cubic_equation":false,
        "cubic_with_square_roots":false,
        "cylinder_and_step":false,
        "cylinder_rolling_down_slope":false,
        "defective_pens":false,
        "derive_suvat":false,
        "diagonal_of_a_cube":false,
        "differential_equation":false,
        "differentiation":false,
        "differentiation_of_arctan":false,
        "discharging_capacitor":false,
        "dolphin_underwater":false,
        "doppler":false,
        "double_integration_by_parts":false,
        "earthed_loops":false,"eggs_1":false,
        "eggs_2":false,"electric_cars":false,
        "electric_heater":false,
        "electrical_power_distribution":false,
        "electrostatic":false,
        "equilateral_triangle_in_a_square":false,
        "escape_velocity":false,
        "estimation":false,
        "eyeball_theorem":false,
        "factor":false,
        "family":false,
        "ferry":false,
        "forces_on_a_body":false,
        "four_spheres":false,
        "fractional_log_base":false,
        "frightening_integral":false,
        "game_show_and_doors":false,
        "garden_hose":false,
        "geostationary_satellite":false,
        "glass_of_lemonade":false,
        "goat_in_field":false,
        "gold_bars":false,
        "graph_sketching1":false,
        "graph_sketching2":false,
        "graph_sketching3":false,
        "gravitational_pull":false,
        "greenhouse_dome":false,
        "hard_differentiation":false,
        "hats":false,
        "hexagon_in_a_circle":false,
        "hidden_quadratic":false,
        "high_voltage_sphere":false,
        "hole_in_sheet":false,
        "horizon":false,
        "hot_air_balloon":false,
        "ice_cream":false,
        "ice_cube":false,
        "icing_doughnuts":false,
        "infinite_loops_circuit_1":false,
        "infinite_loops_circuit_2":false,
        "integral_of_fraction":false,
        "integrals_by_area":false,
        "integration_by_parts":false,
        "inverted_cups":false,
        "jumping_box":false,
        "ladder_in_a_tunnel":false,
        "lego_tower":false,
        "lifeguard":false,
        "linear_momentum":false,
        "logic_game":false,
        "longest_day":false,
        "loop_the_loop":false,
        "loop_the_open_loop":false,
        "lottery":false,
        "lowest_common_multiple":false,
        "magic_square":false,
        "magnetic_field_from_current":false,
        "magnetic_force_on_a_charge":false,
        "magnetic_force_on_a_wire":false,
        "magnetic_induction":false,
        "major_chord":false,
        "many_solutions":false,
        "matrix_inverse":false,
        "matrix_transforms":false,
        "mental_arithmetic":false,
        "mixing_fluids":false,
        "moment_of_inertia":false,
        "momentum":false,
        "moons_orbit_about_the_earth":false,
        "mortgage":false,
        "multiple_geometric_series":false,
        "nastier_differential_equation":false,
        "nasty_differential_equation":false,
        "negative_base":false,
        "nested_fractions":false,
        "nested_square_roots":false,
        "newton_raphson":false,
        "number_of_roots":false,
        "optimisation":false,
        "orchestra_tuning":false,
        "pairing_socks":false,
        "parabolic_mirror":false,
        "parallel_wires":false,
        "partial_fractions_1":false,
        "partial_fractions_2":false,
        "partial_fractions_3":false,
        "particle_filter":false,
        "particle_on_a_hemisphere":false,
        "particles_on_a_spring":false,
        "particles_on_a_string":false,
        "party":false,
        "permuting_digits":false,
        "person_in_lift":false,
        "pile_of_blocks":false,
        "pirate_game":false,
        "piston_tube":false,
        "pistons":false,
        "plasticine_balls":false,
        "probability_density_function":false,
        "projectile_range":false,
        "pulley_and_spring":false,
        "pulley_balance":false,
        "pulley_dynamics_1":false,
        "pulley_dynamics_2":false,
        "pyramid_height":false,
        "quadratic_with_fractions":false,
        "quantitative_cogs":false,
        "quartic_equation":false,
        "random_number_generator":false,
        "resistor_and_capacitor":false,
        "resistor_bridge":false,
        "resistor_cube":false,
        "resistors_with_one_node":false,
        "resistors_with_two_nodes":false,
        "river_crossing":false,
        "rock_paper_scissors":false,
        "rocket":false,
        "rod_on_springs":false,
        "roll_a_die":false,
        "rolling_toy":false,
        "ruler_on_cube":false,
        "ruler_on_fingers":false,
        "shadow_length":false,
        "simple_projectile":false,
        "simultaneous_equations":false,
        "sliding_in_air":false,
        "slingshot_ride":false,
        "socks":false,
        "stick_and_planks":false,
        "string_around_sphere":false,
        "submarine_toy":false,
        "sum_of_3_dice":false,
        "sum_of_integers":false,
        "supermarket_shopping":false,
        "syphon":false,
        "tan_integral":false,
        "target_and_projectile":false,
        "tetrahedron":false,
        "tetrahedron_volume":false,
        "three_by_three_inverse":false,
        "three_by_three_matrix":false,
        "three_cylinders":false,
        "throw_brick_out_of_boat":false,
        "toilet_paper":false,
        "trebuchet_velocity":false,
        "trebuchet_weight":false,
        "truth_and_logic":false,
        "turbine":false,
        "two_source_interference":false,
        "voltage_sources":false,
        "voltmeter":false,
        "waves":false,
        "weird_resistor":false
    },
    aqcompleted: { 
        "as_2013_g482_physicsa_ewp_q6iv": false, 
        "as_2013_4721_maths_c1_q7i": false, 
        "as_2013_g482_physicsa_ewp_q7vi": false, 
        "as_2013_4732_maths_s1_q4iii": false, 
        "as_2013_4722_maths_c2_q6i": false, 
        "as_2013_g481_physicsa_m_q5iv": false, 
        "a2_2013_4729_maths_m2_q1i": false, 
        "as_2013_g482_physicsa_ewp_q6vii": false, 
        "as_2013_g484_physicsa_nw_q3ii": false, 
        "as_2013_4722_maths_c2_q5i": false, 
        "as_2013_4722_maths_c2_q5ii": false, 
        "as_2013_4722_maths_c2_q3i": false, 
        "as_2013_4722_maths_c2_q2ii": false, 
        "a2_2013_4723_maths_c3_q4": false, 
        "as_2013_g484_physicsa_nw_q4ii": false, 
        "as_2013_g481_physicsa_m_q1i": false, 
        "a2_2013_4729_maths_m2_q1ii": false, 
        "as_2013_4732_maths_s1_q5iv": false, 
        "as_2013_4732_maths_s1_q5i": false, 
        "as_2013_4732_maths_s1_q8i": false, 
        "as_2013_g484_physicsa_nw_q1iii": false, 
        "as_2013_g482_physicsa_ewp_q2i": false, 
        "as_2013_g482_physicsa_ewp_q3i": false, 
        "as_2013_4728_maths_m1_q7iii": false, 
        "as_2013_g484_physicsa_nw_q3i": false, 
        "as_2013_4725_maths_fp1_q7ii": false, 
        "as_2013_4721_maths_c1_q5iii": false, 
        "as_2013_4725_maths_fp1_q4": false, 
        "as_2013_g482_physicsa_ewp_q5iii": false, 
        "a2_2013_4723_maths_c3_q1i": false, 
        "a2_2013_4723_maths_c3_q7i": false, 
        "as_2013_4721_maths_c1_q5ii": false, 
        "as_2013_g482_physicsa_ewp_q2vii": false, 
        "as_2013_g484_physicsa_nw_q1ii": false, 
        "as_2013_4721_maths_c1_q6ii": false, 
        "a2_2013_4729_maths_m2_q3ii": false, 
        "as_2013_4725_maths_fp1_q8": false, 
        "a2_2013_4729_maths_m2_q4i": false, 
        "as_2013_4722_maths_c2_q9iii": false, 
        "as_2013_4721_maths_c1_q9iii": false, 
        "as_2013_4722_maths_c2_q3ii": false, 
        "as_2013_4728_maths_m1_q4i": false, 
        "as_2013_4722_maths_c2_2i": false, 
        "as_2013_4721_maths_c1_q4ii": false, 
        "as_2013_g485_physicsa_fpfp_q5i": false, 
        "a2_2013_4723_maths_c3_q1ii": false, 
        "as_2013_4732_maths_s1_q1i": false, 
        "as_2013_4728_maths_m1_q5iii": false, 
        "as_2013_g482_physicsa_ewp_q2ix": false, 
        "a2_2013_4729_maths_m2_q5ii": false, 
        "as_2013_g485_physicsa_fpfp_q5iii": false, 
        "as_2013_g482_physicsa_ewp_q3vii": false, 
        "as_2013_g482_physicsa_ewp_q7iv": false, 
        "as_2013_4721_maths_c1_q5i": false, 
        "as_2013_g482_physicsa_ewp_q1i": false, 
        "as_2013_g484_physicsa_nw_q3vi": false, 
        "as_2013_g481_physicsa_m_q8iv": false, 
        "as_2013_4722_maths_c2_q9i": false, 
        "as_2013_4725_maths_fp1_q2i": false, 
        "as_2013_g481_physicsa_m_q8ii": false, 
        "as_2013_4721_maths_c1_q4iii": false, 
        "as_2013_g482_physicsa_ewp_q1ii": false, 
        "as_2013_g482_physicsa_ewp_q4iii": false, 
        "as_2013_4722_maths_c2_q4iii": false, 
        "a2_2013_4729_maths_m2_q7iii": false, 
        "a2_2013_4723_maths_c3_q3i": false, 
        "a2_2013_4729_maths_m2_q3i": false, 
        "as_2013_g482_physicsa_ewp_q2vi": false, 
        "as_2013_4721_maths_c1_q9i": false, 
        "as_2013_4728_maths_m1_q3i": false, 
        "as_2013_g482_physicsa_ewp_q3viii": false, 
        "as_2013_4732_maths_s1_q7iii": false, 
        "as_2013_4732_maths_s1_q9iii": false, 
        "a2_2013_4724_maths_c4_q5ii": false, 
        "as_2013_g484_physicsa_nw_q6iv": false, 
        "as_2013_g482_physicsa_ewp_q6viii": false, 
        "as_2013_g481_physicsa_m_q4ii": false, 
        "as_2013_g482_physicsa_ewp_q6iii": false, 
        "as_2013_4722_maths_c2_q8ii": false, 
        "as_2013_4725_maths_fp1_q1": false, 
        "as_2013_g482_physicsa_ewp_q4vii": false, 
        "a2_2013_4724_maths_c4_q8ii": false, 
        "a2_2013_4724_maths_c4_q5i": false, 
        "a2_2013_4723_maths_c3_q8i": false, 
        "as_2013_g482_physicsa_ewp_q6i": false, 
        "as_2013_4725_maths_fp1_q10i": false, 
        "as_2013_4728_maths_m1_q6i": false, 
        "as_2013_g481_physicsa_m_q8iii": false, 
        "a2_2013_4724_maths_c4_q10i": false, 
        "as_2013_g485_physicsa_fpfp_q2iv": false, 
        "a2_2013_4729_maths_m2_q5i": false, 
        "a2_2013_4723_maths_c3_q5i": false, 
        "as_2013_4721_maths_c1_q8": false, 
        "as_2013_4732_maths_s1_q3i": false, 
        "as_2013_4721_maths_c1_q9ii": false, 
        "a2_2013_4729_maths_m2_q4ii": false, 
        "as_2013_4722_maths_c2_q7ii": false, 
        "as_2013_g485_physicsa_fpfp_q3iii": false, 
        "as_2013_4725_maths_fp1_q7iii": false, 
        "a2_2013_4723_maths_c3_q6ii": false, 
        "as_2013_g484_physicsa_nw_q3iii": false, 
        "a2_2013_4724_maths_c4_q8i": false, 
        "as_2013_4732_maths_s1_q6i": false, 
        "as_2013_g484_physicsa_nw_q4iv": false, 
        "a2_2013_4723_maths_c3_q7ii": false, 
        "as_2013_g482_physicsa_ewp_q6ii": false, 
        "as_2013_4722_maths_c2_q4ii": false, 
        "as_2013_g484_physicsa_nw_q2v": false, 
        "as_2013_4722_maths_c2_q7i": false, 
        "as_2013_g482_physicsa_ewp_q5i": false, 
        "a2_2013_4724_maths_c4_q7i": false, 
        "as_2013_4721_maths_c1_q2": false, 
        "a2_2013_4723_maths_c3_q8ii": false, 
        "as_2013_g482_physicsa_ewp_q5ii": false, 
        "as_2013_g481_physicsa_m_q3i": false, 
        "as_2013_g481_physicsa_m_q4iii": false, 
        "as_2013_4722_maths_c2_q9ii": false, 
        "as_2013_g481_physicsa_m_q6i": false, 
        "as_2013_4732_maths_s1_q9i": false, 
        "as_2013_g482_physicsa_ewp_q7ii": false, 
        "as_2013_g484_physicsa_nw_q6i": false, 
        "a2_2013_4723_maths_c3_q6i": false, 
        "as_2013_4728_maths_m1_q5i": false, 
        "as_2013_g482_physicsa_ewp_q4vi": false, 
        "as_2013_g481_physicsa_m_q2ii": false, 
        "as_2013_g482_physicsa_ewp_q7v": false, 
        "as_2013_g484_physicsa_nw_q4iii": false, 
        "as_2013_4721_maths_c1_q4i": false, 
        "as_2013_4721_maths_c1_q10i": false, 
        "as_2013_4728_maths_m1_q7i": false, 
        "as_2013_4732_maths_s1_q4ii": false, 
        "as_2013_g482_physicsa_ewp_q7i": false, 
        "as_2013_4728_maths_m1_q1i": false, 
        "as_2013_4728_maths_m1_q3iii": false, 
        "as_2013_g484_physicsa_nw_q5ii": false, 
        "as_2013_g485_physicsa_fpfp_q2ii": false, 
        "as_2013_4728_maths_m1_q2ii": false, 
        "as_2013_g481_physicsa_m_q6ii": false, 
        "as_2013_g484_physicsa_nw_q6iii": false, 
        "as_2013_4732_maths_s1_q2ii": false, 
        "as_2013_g484_physicsa_nw_q3v": false, 
        "as_2013_4732_maths_s1_q6ii": false, 
        "a2_2013_4724_maths_c4_q9iii": false, 
        "a2_2013_4723_maths_c3_q9ii": false, 
        "as_2013_g481_physicsa_m_q1ii": false, 
        "as_2013_g482_physicsa_ewp_q7iii": false, 
        "as_2013_g481_physicsa_m_q4iv": false, 
        "as_2013_g485_physicsa_fpfp_q3i": false, 
        "as_2013_g482_physicsa_ewp_q6v": false, 
        "as_2013_4728_maths_m1_q5ii": false, 
        "as_2013_g481_physicsa_m_q7i": false, 
        "a2_2013_4723_maths_c3_q3ii": false, 
        "as_2013_g482_physicsa_ewp_q3iii": false, 
        "as_2013_g484_physicsa_nw_q5iii": false, 
        "as_2013_g481_physicsa_m_q5ii": false, 
        "as_2013_g485_physicsa_fpfp_q4i": false, 
        "as_2013_g482_physicsa_ewp_q3ii": false, 
        "as_2013_g481_physicsa_m_q3v": false, 
        "as_2013_4728_maths_m1_q1ii": false, 
        "as_2013_g485_physicsa_fpfp_q1iii": false, 
        "as_2013_4721_maths_c1_q6i": false, 
        "as_2013_g482_physicsa_ewp_q4iv": false, 
        "as_2013_g484_physicsa_nw_q5i": false, 
        "as_2013_g482_physicsa_ewp_q3vi": false, 
        "as_2013_4721_maths_c1_q1iii": false, 
        "as_2013_g485_physicsa_fpfp_q2i": false, 
        "as_2013_g481_physicsa_m_q7ii": false, 
        "as_2013_4725_maths_fp1_q3": false, 
        "a2_2013_4724_maths_c4_q10ii": false, 
        "a2_2013_4723_maths_c3_q2ii": false, 
        "a2_2013_4724_maths_c4_q1": false, 
        "as_2013_g484_physicsa_nw_q2i": false, 
        "as_2013_g484_physicsa_nw_q6ii": false, 
        "a2_2013_4729_maths_m2_q7i": false, 
        "as_2013_g485_physicsa_fpfp_q1i": false, 
        "as_2013_g481_physicsa_m_q5i": false, 
        "as_2013_4725_maths_fp1_q10ii": false, 
        "as_2013_g481_physicsa_m_q3iii": false, 
        "as_2013_g485_physicsa_fpfp_q5iv": false, 
        "as_2013_4725_maths_fp1_q6ii": false, 
        "as_2013_g485_physicsa_fpfp_q2iii": false, 
        "a2_2013_4723_maths_c3_q7iv": false, 
        "as_2013_g481_physicsa_m_q4i": false, 
        "as_2013_g482_physicsa_ewp_q4i": false, 
        "a2_2013_4729_maths_m2_q6ii": false, 
        "as_2013_g481_physicsa_m_q4v": false, 
        "as_2013_g485_physicsa_fpfp_q4ii": false, 
        "as_2013_4728_maths_m1_q4iii": false, 
        "as_2013_g482_physicsa_ewp_q5iv": false, 
        "as_2013_4721_maths_c1_q7ii": false, 
        "as_2013_g484_physicsa_nw_q3iv": false, 
        "as_2013_4722_maths_c2_q4i": false, 
        "as_2013_g484_physicsa_nw_q2iii": false, 
        "as_2013_4722_maths_c2_q6ii": false, 
        "as_2013_4732_maths_s1_q1ii": false, 
        "as_2013_4728_maths_m1_q3iv": false, 
        "a2_2013_4729_maths_m2_q2ii": false, 
        "a2_2013_4724_maths_c4_q10iii": false, 
        "a2_2013_4723_maths_c3_q5ii": false, 
        "as_2013_4728_maths_m1_q6ii": false, 
        "as_2013_4721_maths_c1_q10iii": false, 
        "as_2013_4732_maths_s1_q9iv": false, 
        "as_2013_g485_physicsa_fpfp_q1ii": false, 
        "as_2013_4722_maths_c2_q1": false, 
        "as_2013_g484_physicsa_nw_q5iv": false, 
        "as_2013_4728_maths_m1_q2iii": false, 
        "as_2013_g482_physicsa_ewp_q2ii": false, 
        "as_2013_g482_physicsa_ewp_q2iv": false, 
        "as_2013_4732_maths_s1_q2i": false, 
        "as_2013_4721_maths_c1_q10ii": false, 
        "as_2013_4732_maths_s1_q3iii": false, 
        "as_2013_4732_maths_s1_q8ii": false, 
        "as_2013_4732_maths_s1_q9ii": false, 
        "as_2013_4722_maths_c2_q8iii": false, 
        "as_2013_g482_physicsa_ewp_q3v": false, 
        "a2_2013_4724_maths_c4_q9i": false, 
        "as_2013_g481_physicsa_m_q6iv": false, 
        "as_2013_4728_maths_m1_q6iii": false, 
        "as_2013_g482_physicsa_ewp_q5v": false, 
        "as_2013_g481_physicsa_m_q2iii": false, 
        "as_2013_4725_maths_fp1_q7iv": false, 
        "a2_2013_4729_maths_m2_q7iv": false, 
        "a2_2013_4729_maths_m2_q2i": false, 
        "as_2013_4732_maths_s1_q7ii": false, 
        "a2_2013_4724_maths_c4_q9ii": false, 
        "as_2013_4728_maths_m1_q3ii": false, 
        "a2_2013_4723_maths_c3_q7iii": false, 
        "as_2013_4732_maths_s1_q1iii": false, 
        "a2_2013_4724_maths_c4_q2": false, 
        "as_2013_g485_physicsa_fpfp_q3v": false, 
        "as_2013_4721_maths_c1_q3i": false, 
        "as_2013_g482_physicsa_ewp_q3iv": false, 
        "as_2013_4732_maths_s1_q6iii": false, 
        "as_2013_g481_physicsa_m_q3vi": false, 
        "as_2013_4721_maths_c1_q1ii": false, 
        "as_2013_4728_maths_m1_q4ii": false, 
        "a2_2013_4729_maths_m2_q7ii": false, 
        "as_2013_g481_physicsa_m_q5iii": false, 
        "a2_2013_4724_maths_c4_q4": false, 
        "as_2013_g485_physicsa_fpfp_q4iii": false, 
        "as_2013_g482_physicsa_ewp_q1iv": false, 
        "a2_2013_4729_maths_m2_q6i": false, 
        "as_2013_g481_physicsa_m_q6iii": false, 
        "a2_2013_4724_maths_c4_q6": false, 
        "as_2013_4722_maths_c2_q8i": false, 
        "as_2013_g482_physicsa_ewp_q6vi": false, 
        "as_2013_g484_physicsa_nw_q2iv": false, 
        "a2_2013_4724_maths_c4_q10iv": false, 
        "as_2013_g482_physicsa_ewp_q7vii": false, 
        "as_2013_4725_maths_fp1_q9i": false, 
        "as_2013_4725_maths_fp1_q9ii": false, 
        "as_2013_4732_maths_s1_q5ii": false, 
        "as_2013_g485_physicsa_fpfp_q3iv": false, 
        "as_2013_g481_physicsa_m_q5v": false, 
        "as_2013_g482_physicsa_ewp_q2viii": false, 
        "as_2013_g484_physicsa_nw_q1i": false, 
        "a2_2013_4723_maths_c3_q9i": false, 
        "as_2013_4721_maths_c1_q1i": false, 
        "as_2013_g482_physicsa_ewp_q1iii": false, 
        "a2_2013_4723_maths_c3_q6iii": false, 
        "as_2013_4725_maths_fp1_q7i": false, 
        "as_2013_4728_maths_m1_q2i": false, 
        "as_2013_4728_maths_m1_q7ii": false, 
        "a2_2013_4724_maths_c4_q7ii": false, 
        "as_2013_g481_physicsa_m_q2i": false, 
        "as_2013_g482_physicsa_ewp_q2iii": false, 
        "as_2013_4732_maths_s1_q3ii": false, 
        "as_2013_g484_physicsa_nw_q1iv": false, 
        "as_2013_g481_physicsa_m_q8i": false, 
        "a2_2013_4724_maths_c4_q7iii": false, 
        "as_2013_4725_maths_fp1_q5": false, 
        "as_2013_g485_physicsa_fpfp_q3ii": false, 
        "a2_2013_4729_maths_m2_q6iii": false, 
        "as_2013_g485_physicsa_fpfp_q5ii": false, 
        "as_2013_4725_maths_fp1_q6i": false, 
        "a2_2013_4724_maths_c4_q3": false, 
        "as_2013_g481_physicsa_m_q7iii": false, 
        "as_2013_4732_maths_s1_q7i": false, 
        "as_2013_g484_physicsa_nw_q4i": false, 
        "as_2013_g482_physicsa_ewp_q4ii": false, 
        "as_2013_4721_maths_c1_q3ii": false, 
        "a2_2013_4723_maths_c3_q8iii": false, 
        "as_2013_g484_physicsa_nw_q2ii": false, 
        "as_2013_g482_physicsa_ewp_q4v": false, 
        "as_2013_4732_maths_s1_q4i": false, 
        "as_2013_4725_maths_fp1_q2ii": false, 
        "as_2013_g482_physicsa_ewp_q2v": false, 
        "as_2013_g481_physicsa_m_q3ii": false, 
        "a2_2013_4723_maths_c3_q2i": false, 
        "as_2013_g481_physicsa_m_q3iv": false, 
        "as_2013_4732_maths_s1_q5iii": false 
    },
    aqcompletedorder: [
        "as_2013_4721_maths_c1_q1i",
        "as_2013_4721_maths_c1_q1ii",
        "as_2013_4721_maths_c1_q1iii",
        "as_2013_4721_maths_c1_q2",
        "as_2013_4721_maths_c1_q3i",
        "as_2013_4721_maths_c1_q3ii",
        "as_2013_4721_maths_c1_q4i",
        "as_2013_4721_maths_c1_q4ii",
        "as_2013_4721_maths_c1_q4iii",
        "as_2013_4721_maths_c1_q5i",
        "as_2013_4721_maths_c1_q5ii",
        "as_2013_4721_maths_c1_q5iii",
        "as_2013_4721_maths_c1_q6i",
        "as_2013_4721_maths_c1_q6ii",
        "as_2013_4721_maths_c1_q7i",
        "as_2013_4721_maths_c1_q7ii",
        "as_2013_4721_maths_c1_q8",
        "as_2013_4721_maths_c1_q9i",
        "as_2013_4721_maths_c1_q9ii",
        "as_2013_4721_maths_c1_q9iii",
        "as_2013_4721_maths_c1_q10i",
        "as_2013_4721_maths_c1_q10ii",
        "as_2013_4721_maths_c1_q10iii",
        "as_2013_4722_maths_c2_q1",
        "as_2013_4722_maths_c2_2i",
        "as_2013_4722_maths_c2_q2ii",
        "as_2013_4722_maths_c2_q3i",
        "as_2013_4722_maths_c2_q3ii",
        "as_2013_4722_maths_c2_q4i",
        "as_2013_4722_maths_c2_q4ii",
        "as_2013_4722_maths_c2_q4iii",
        "as_2013_4722_maths_c2_q5i",
        "as_2013_4722_maths_c2_q5ii",
        "as_2013_4722_maths_c2_q6i",
        "as_2013_4722_maths_c2_q6ii",
        "as_2013_4722_maths_c2_q7i",
        "as_2013_4722_maths_c2_q7ii",
        "as_2013_4722_maths_c2_q8i",
        "as_2013_4722_maths_c2_q8ii",
        "as_2013_4722_maths_c2_q8iii",
        "as_2013_4722_maths_c2_q9i",
        "as_2013_4722_maths_c2_q9ii",
        "as_2013_4722_maths_c2_q9iii",
        "a2_2013_4723_maths_c3_q1i",
        "a2_2013_4723_maths_c3_q1ii",
        "a2_2013_4723_maths_c3_q2i",
        "a2_2013_4723_maths_c3_q2ii",
        "a2_2013_4723_maths_c3_q3i",
        "a2_2013_4723_maths_c3_q3ii",
        "a2_2013_4723_maths_c3_q4",
        "a2_2013_4723_maths_c3_q5i",
        "a2_2013_4723_maths_c3_q5ii",
        "a2_2013_4723_maths_c3_q6i",
        "a2_2013_4723_maths_c3_q6ii",
        "a2_2013_4723_maths_c3_q6iii",
        "a2_2013_4723_maths_c3_q7i",
        "a2_2013_4723_maths_c3_q7ii",
        "a2_2013_4723_maths_c3_q7iii",
        "a2_2013_4723_maths_c3_q7iv",
        "a2_2013_4723_maths_c3_q8i",
        "a2_2013_4723_maths_c3_q8ii",
        "a2_2013_4723_maths_c3_q8iii",
        "a2_2013_4723_maths_c3_q9i",
        "a2_2013_4723_maths_c3_q9ii",
        "a2_2013_4724_maths_c4_q1",
        "a2_2013_4724_maths_c4_q2",
        "a2_2013_4724_maths_c4_q3",
        "a2_2013_4724_maths_c4_q4",
        "a2_2013_4724_maths_c4_q5i",
        "a2_2013_4724_maths_c4_q5ii",
        "a2_2013_4724_maths_c4_q6",
        "a2_2013_4724_maths_c4_q7i",
        "a2_2013_4724_maths_c4_q7ii",
        "a2_2013_4724_maths_c4_q7iii",
        "a2_2013_4724_maths_c4_q8i",
        "a2_2013_4724_maths_c4_q8ii",
        "a2_2013_4724_maths_c4_q9i",
        "a2_2013_4724_maths_c4_q9ii",
        "a2_2013_4724_maths_c4_q9iii",
        "a2_2013_4724_maths_c4_q10i",
        "a2_2013_4724_maths_c4_q10ii",
        "a2_2013_4724_maths_c4_q10iii",
        "a2_2013_4724_maths_c4_q10iv",
        "as_2013_g481_physicsa_m_q1i",
        "as_2013_g481_physicsa_m_q1ii",
        "as_2013_g481_physicsa_m_q2i",
        "as_2013_g481_physicsa_m_q2ii",
        "as_2013_g481_physicsa_m_q2iii",
        "as_2013_g481_physicsa_m_q3i",
        "as_2013_g481_physicsa_m_q3ii",
        "as_2013_g481_physicsa_m_q3iii",
        "as_2013_g481_physicsa_m_q3iv",
        "as_2013_g481_physicsa_m_q3v",
        "as_2013_g481_physicsa_m_q3vi",
        "as_2013_g481_physicsa_m_q4i",
        "as_2013_g481_physicsa_m_q4ii",
        "as_2013_g481_physicsa_m_q4iii",
        "as_2013_g481_physicsa_m_q4iv",
        "as_2013_g481_physicsa_m_q4v",
        "as_2013_g481_physicsa_m_q5i",
        "as_2013_g481_physicsa_m_q5ii",
        "as_2013_g481_physicsa_m_q5iii",
        "as_2013_g481_physicsa_m_q5iv",
        "as_2013_g481_physicsa_m_q5v",
        "as_2013_g481_physicsa_m_q6i",
        "as_2013_g481_physicsa_m_q6ii",
        "as_2013_g481_physicsa_m_q6iii",
        "as_2013_g481_physicsa_m_q6iv",
        "as_2013_g481_physicsa_m_q7i",
        "as_2013_g481_physicsa_m_q7ii",
        "as_2013_g481_physicsa_m_q7iii",
        "as_2013_g481_physicsa_m_q8i",
        "as_2013_g481_physicsa_m_q8ii",
        "as_2013_g481_physicsa_m_q8iii",
        "as_2013_g481_physicsa_m_q8iv",
        "as_2013_4721_maths_c1_q1i",
        "as_2013_4721_maths_c1_q1ii",
        "as_2013_4721_maths_c1_q1iii",
        "as_2013_4721_maths_c1_q2",
        "as_2013_4721_maths_c1_q3i",
        "as_2013_4721_maths_c1_q3ii",
        "as_2013_4721_maths_c1_q4i",
        "as_2013_4721_maths_c1_q4ii",
        "as_2013_4721_maths_c1_q4iii",
        "as_2013_4721_maths_c1_q5i",
        "as_2013_4721_maths_c1_q5ii",
        "as_2013_4721_maths_c1_q5iii",
        "as_2013_4721_maths_c1_q6i",
        "as_2013_4721_maths_c1_q6ii",
        "as_2013_4721_maths_c1_q7i",
        "as_2013_4721_maths_c1_q7ii",
        "as_2013_4721_maths_c1_q8",
        "as_2013_4721_maths_c1_q9i",
        "as_2013_4721_maths_c1_q9ii",
        "as_2013_4721_maths_c1_q9iii",
        "as_2013_4721_maths_c1_q10i",
        "as_2013_4721_maths_c1_q10ii",
        "as_2013_4721_maths_c1_q10iii",
        "as_2013_4728_maths_m1_q1i",
        "as_2013_4728_maths_m1_q1ii",
        "as_2013_4728_maths_m1_q2i",
        "as_2013_4728_maths_m1_q2ii",
        "as_2013_4728_maths_m1_q2iii",
        "as_2013_4728_maths_m1_q3i",
        "as_2013_4728_maths_m1_q3ii",
        "as_2013_4728_maths_m1_q3iii",
        "as_2013_4728_maths_m1_q3iv",
        "as_2013_4728_maths_m1_q4i",
        "as_2013_4728_maths_m1_q4ii",
        "as_2013_4728_maths_m1_q4iii",
        "as_2013_4728_maths_m1_q5i",
        "as_2013_4728_maths_m1_q5ii",
        "as_2013_4728_maths_m1_q5iii",
        "as_2013_4728_maths_m1_q6i",
        "as_2013_4728_maths_m1_q6ii",
        "as_2013_4728_maths_m1_q6iii",
        "as_2013_4728_maths_m1_q7i",
        "as_2013_4728_maths_m1_q7ii",
        "as_2013_4728_maths_m1_q7iii",
        "a2_2013_4729_maths_m2_q1i",
        "a2_2013_4729_maths_m2_q1ii",
        "a2_2013_4729_maths_m2_q2i",
        "a2_2013_4729_maths_m2_q2ii",
        "a2_2013_4729_maths_m2_q3i",
        "a2_2013_4729_maths_m2_q3ii",
        "a2_2013_4729_maths_m2_q4i",
        "a2_2013_4729_maths_m2_q4ii",
        "a2_2013_4729_maths_m2_q5i",
        "a2_2013_4729_maths_m2_q5ii",
        "a2_2013_4729_maths_m2_q6i",
        "a2_2013_4729_maths_m2_q6ii",
        "a2_2013_4729_maths_m2_q6iii",
        "a2_2013_4729_maths_m2_q7i",
        "a2_2013_4729_maths_m2_q7ii",
        "a2_2013_4729_maths_m2_q7iii",
        "a2_2013_4729_maths_m2_q7iv",
        "as_2013_4732_maths_s1_q1i",
        "as_2013_4732_maths_s1_q1ii",
        "as_2013_4732_maths_s1_q1iii",
        "as_2013_4732_maths_s1_q2i",
        "as_2013_4732_maths_s1_q2ii",
        "as_2013_4732_maths_s1_q3i",
        "as_2013_4732_maths_s1_q3ii",
        "as_2013_4732_maths_s1_q3iii",
        "as_2013_4732_maths_s1_q4i",
        "as_2013_4732_maths_s1_q4ii",
        "as_2013_4732_maths_s1_q4iii",
        "as_2013_4732_maths_s1_q5i",
        "as_2013_4732_maths_s1_q5ii",
        "as_2013_4732_maths_s1_q5iii",
        "as_2013_4732_maths_s1_q5iv",
        "as_2013_4732_maths_s1_q6i",
        "as_2013_4732_maths_s1_q6ii",
        "as_2013_4732_maths_s1_q6iii",
        "as_2013_4732_maths_s1_q7i",
        "as_2013_4732_maths_s1_q7ii",
        "as_2013_4732_maths_s1_q7iii",
        "as_2013_4732_maths_s1_q8i",
        "as_2013_4732_maths_s1_q8ii",
        "as_2013_4732_maths_s1_q9i",
        "as_2013_4732_maths_s1_q9ii",
        "as_2013_4732_maths_s1_q9iii",
        "as_2013_4732_maths_s1_q9iv",
        "as_2013_4725_maths_fp1_q1",
        "as_2013_4725_maths_fp1_q2i",
        "as_2013_4725_maths_fp1_q2ii",
        "as_2013_4725_maths_fp1_q3",
        "as_2013_4725_maths_fp1_q4",
        "as_2013_4725_maths_fp1_q5",
        "as_2013_4725_maths_fp1_q6i",
        "as_2013_4725_maths_fp1_q6ii",
        "as_2013_4725_maths_fp1_q7i",
        "as_2013_4725_maths_fp1_q7ii",
        "as_2013_4725_maths_fp1_q7iii",
        "as_2013_4725_maths_fp1_q7iv",
        "as_2013_4725_maths_fp1_q8",
        "as_2013_4725_maths_fp1_q9i",
        "as_2013_4725_maths_fp1_q9ii",
        "as_2013_4725_maths_fp1_q10i",
        "as_2013_4725_maths_fp1_q10ii",
        "as_2013_g482_physicsa_ewp_q1i",
        "as_2013_g482_physicsa_ewp_q1ii",
        "as_2013_g482_physicsa_ewp_q1iii",
        "as_2013_g482_physicsa_ewp_q1iv",
        "as_2013_g482_physicsa_ewp_q2i",
        "as_2013_g482_physicsa_ewp_q2ii",
        "as_2013_g482_physicsa_ewp_q2iii",
        "as_2013_g482_physicsa_ewp_q2iv",
        "as_2013_g482_physicsa_ewp_q2ix",
        "as_2013_g482_physicsa_ewp_q2v",
        "as_2013_g482_physicsa_ewp_q2vi",
        "as_2013_g482_physicsa_ewp_q2vii",
        "as_2013_g482_physicsa_ewp_q2viii",
        "as_2013_g482_physicsa_ewp_q3i",
        "as_2013_g482_physicsa_ewp_q3ii",
        "as_2013_g482_physicsa_ewp_q3iii",
        "as_2013_g482_physicsa_ewp_q3iv",
        "as_2013_g482_physicsa_ewp_q3v",
        "as_2013_g482_physicsa_ewp_q3vi",
        "as_2013_g482_physicsa_ewp_q3vii",
        "as_2013_g482_physicsa_ewp_q3viii",
        "as_2013_g482_physicsa_ewp_q4i",
        "as_2013_g482_physicsa_ewp_q4ii",
        "as_2013_g482_physicsa_ewp_q4iii",
        "as_2013_g482_physicsa_ewp_q4iv",
        "as_2013_g482_physicsa_ewp_q4v",
        "as_2013_g482_physicsa_ewp_q4vi",
        "as_2013_g482_physicsa_ewp_q4vii",
        "as_2013_g482_physicsa_ewp_q5i",
        "as_2013_g482_physicsa_ewp_q5ii",
        "as_2013_g482_physicsa_ewp_q5iii",
        "as_2013_g482_physicsa_ewp_q5iv",
        "as_2013_g482_physicsa_ewp_q5v",
        "as_2013_g482_physicsa_ewp_q6i",
        "as_2013_g482_physicsa_ewp_q6ii",
        "as_2013_g482_physicsa_ewp_q6iii",
        "as_2013_g482_physicsa_ewp_q6iv",
        "as_2013_g482_physicsa_ewp_q6v",
        "as_2013_g482_physicsa_ewp_q6vi",
        "as_2013_g482_physicsa_ewp_q6vii",
        "as_2013_g482_physicsa_ewp_q6viii",
        "as_2013_g482_physicsa_ewp_q7i",
        "as_2013_g482_physicsa_ewp_q7ii",
        "as_2013_g482_physicsa_ewp_q7iii",
        "as_2013_g482_physicsa_ewp_q7iv",
        "as_2013_g482_physicsa_ewp_q7v",
        "as_2013_g482_physicsa_ewp_q7vi",
        "as_2013_g482_physicsa_ewp_q7vii",
        "as_2013_g484_physicsa_nw_q1i",
        "as_2013_g484_physicsa_nw_q1ii",
        "as_2013_g484_physicsa_nw_q1iii",
        "as_2013_g484_physicsa_nw_q1iv",
        "as_2013_g484_physicsa_nw_q2i",
        "as_2013_g484_physicsa_nw_q2ii",
        "as_2013_g484_physicsa_nw_q2iii",
        "as_2013_g484_physicsa_nw_q2iv",
        "as_2013_g484_physicsa_nw_q2v",
        "as_2013_g484_physicsa_nw_q3i",
        "as_2013_g484_physicsa_nw_q3ii",
        "as_2013_g484_physicsa_nw_q3iii",
        "as_2013_g484_physicsa_nw_q3iv",
        "as_2013_g484_physicsa_nw_q3v",
        "as_2013_g484_physicsa_nw_q3vi",
        "as_2013_g484_physicsa_nw_q4i",
        "as_2013_g484_physicsa_nw_q4ii",
        "as_2013_g484_physicsa_nw_q4iii",
        "as_2013_g484_physicsa_nw_q4iv",
        "as_2013_g484_physicsa_nw_q5i",
        "as_2013_g484_physicsa_nw_q5ii",
        "as_2013_g484_physicsa_nw_q5iii",
        "as_2013_g484_physicsa_nw_q5iv",
        "as_2013_g484_physicsa_nw_q6i",
        "as_2013_g484_physicsa_nw_q6ii",
        "as_2013_g484_physicsa_nw_q6iii",
        "as_2013_g484_physicsa_nw_q6iv",
        "as_2013_g485_physicsa_fpfp_q1i",
        "as_2013_g485_physicsa_fpfp_q1ii",
        "as_2013_g485_physicsa_fpfp_q1iii",
        "as_2013_g485_physicsa_fpfp_q2i",
        "as_2013_g485_physicsa_fpfp_q2ii",
        "as_2013_g485_physicsa_fpfp_q2iii",
        "as_2013_g485_physicsa_fpfp_q2iv",
        "as_2013_g485_physicsa_fpfp_q3i",
        "as_2013_g485_physicsa_fpfp_q3ii",
        "as_2013_g485_physicsa_fpfp_q3iii",
        "as_2013_g485_physicsa_fpfp_q3iv",
        "as_2013_g485_physicsa_fpfp_q3v",
        "as_2013_g485_physicsa_fpfp_q4i",
        "as_2013_g485_physicsa_fpfp_q4ii",
        "as_2013_g485_physicsa_fpfp_q4iii",
        "as_2013_g485_physicsa_fpfp_q5i",
        "as_2013_g485_physicsa_fpfp_q5ii",
        "as_2013_g485_physicsa_fpfp_q5iii",
        "as_2013_g485_physicsa_fpfp_q5iv",
    ],
    setAllCompleted: (completed) => {},
    setAllAQCompleted: (aqcompleted) => {},
    setQuestionCompleted: (questionId) => {},
    setALQuestionCompleted: (questionId) => {},
    getCompleted: ()=>{},
    getAQCompleted: () => {}
});