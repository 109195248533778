import React, { useEffect, useState, useContext } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory
  } from "react-router-dom";
import { ReactSVG } from 'react-svg'
import { auth, firestore } from "../../services/firebase";
import GlobalState from '../../context/GlobalState';
import context from '../../context/context';
import "fontsource-clear-sans"
import smalllogo from '../../smalllogo.svg';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function styles(width) {
    let fullPageWidth = "80rem"
    let headerFontSize = "3rem"
    let h1FontSize = '3.7rem'
    let orFontSize = '2.2rem'
    let pFontSize = '1.3rem'
    let bottomLinks = '1.1rem'
    let mobile = false
    let sectionMarginBottom = '4.5rem'
    let topLinks = '1.1rem'
    let paddingRight = '3rem'
    let lineHeight = '1.5rem'
    let mobileButton = '20%'
    if (width >= 1300) {
        fullPageWidth = "46rem"
        headerFontSize = "3rem"
        pFontSize = '1.3rem'
        h1FontSize = '3.7rem'
        orFontSize = '2.2rem'
        mobileButton = '20%'
        mobile = false
    } else if (width < 1300 && width >= 760) {
        fullPageWidth = "46rem"
        headerFontSize = "3rem"
        pFontSize = '1.3rem'
        h1FontSize = '3.7rem'
        orFontSize = '2.2rem'
        mobileButton = '40%'
        mobile = false
    } else if (width < 760 ) {
        fullPageWidth = "20rem"
        headerFontSize = "1.75rem"
        pFontSize = '1rem'
        h1FontSize = '2.5rem'
        orFontSize = '1.5rem'
        mobileButton = '100%'
        mobile = true
        sectionMarginBottom  = '2rem'
        topLinks = '0.9rem'
        paddingRight = '1rem'
        lineHeight = '1.1rem'
    }
    return { fullPageWidth, headerFontSize, pFontSize, mobile, sectionMarginBottom, h1FontSize, orFontSize, topLinks, paddingRight, lineHeight, mobileButton }
}

function TermsOfUse() {
    const history = useHistory();
    const { completed, setAllCompleted } = useContext(context);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
          setWindowDimensions(getWindowDimensions());
        }
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column',  width: windowDimensions.width-(styles(windowDimensions.width).mobile ? 8 : 64), flex: 1, alignItems: 'center', height: '100vh', fontFamily: "Clear Sans, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif", margin: 'auto' }}>
        <div style={{ 
            marginBottom: styles(windowDimensions.width).mobile ? '2rem' : '6rem',
            display: 'flex', 
            flexDirection: 'row', 
            borderBottomWidth: '0.225rem',
            borderBottomStyle: 'solid',
            borderBottomColor: 'rgb(236, 240, 241)',
            width: '100%'
        }}>
            <div style={{ display: 'flex', flex: 2, height: '4.5rem' }}>
                <Link to="/"> 
                    <ReactSVG beforeInjection={(svg) => {
                        svg.classList.add('svg-class-name')
                        svg.setAttribute('style', 'height: 4.5rem; width: 4rem; padding: 0.75rem 0 0.75rem 0.75rem')
                    }} src={smalllogo} style={{marginBottom: '0.75rem'}} />
                </Link>
            </div>
            <div style={{ display: 'flex', flex: 4, alignItems: 'center', justifyContent: 'flex-end' }}>
                <div style={{ marginRight: '-1rem', display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Link style={{ display: 'flex', paddingRight: styles(windowDimensions.width).paddingRight, fontSize: styles(windowDimensions.width).topLinks, fontWeight: 400, lineHeight: styles(windowDimensions.width).lineHeight, color: '#000' }} to="/questionlist">
                        Problem Index
                    </Link>
                    <Link style={{ display: 'flex', paddingRight: styles(windowDimensions.width).paddingRight, fontSize: styles(windowDimensions.width).topLinks, fontWeight: 400, lineHeight: styles(windowDimensions.width).lineHeight, opacity: 0.5, color: '#000' }} onClick={(e) => {
                        e.preventDefault();
                        const completedQuestions = Object.fromEntries(
                            Object.entries(completed).filter(([key, value]) => value === false) )
                        //console.log(Object.keys(completedQuestions).length*Math.random())
                        let randomIndex = 0
                        let linkTo = `/q/${Object.keys(completed)[randomIndex]}`
                        //console.log(Object.keys(completedQuestions).length)
                        if (Object.keys(completedQuestions).length*Math.random() === 0) {
                            randomIndex = Math.floor((Object.keys(completed).length)*Math.random())
                            linkTo = `/q/${Object.keys(completed)[randomIndex]}`
                        } else {
                            randomIndex = Math.floor((Object.keys(completedQuestions).length)*Math.random())
                            linkTo = `/q/${Object.keys(completedQuestions)[randomIndex]}`
                        }
                        
                        //console.log("linkTo", linkTo)
                        history.push(`${linkTo}`)
                    }}>
                        Problem Generator
                    </Link>
                    {auth().currentUser ? 
                    <Link style={{ display: 'flex', paddingRight: styles(windowDimensions.width).paddingRight, fontSize: styles(windowDimensions.width).topLinks, fontWeight: 400, lineHeight: styles(windowDimensions.width).lineHeight, opacity: 0.5, color: '#000' }} onClick={(e) => {
                        e.preventDefault()
                        auth().signOut()
                    }}>{`Logout`}<br />{auth().currentUser.email}</Link>
                    : <Link style={{ display: 'flex', paddingRight: styles(windowDimensions.width).paddingRight, fontSize: styles(windowDimensions.width).topLinks, fontWeight: 400, lineHeight: styles(windowDimensions.width).lineHeight, opacity: 0.5, color: '#000' }} to="/login">
                        {`Login or Sign Up`}
                    </Link>}
                </div>
            </div>
        </div>
        <div style={{ 
            marginBottom: '2rem', 
            paddingBottom: '0rem', 
            display: 'flex', 
            flexDirection: 'column', 
            width: styles(windowDimensions.width).fullPageWidth, 
            alignItems: 'center', 
            justifyContent: 'center',
            borderBottomWidth: '0.225rem',
            borderBottomStyle: 'solid',
            borderBottomColor: 'rgb(236, 240, 241)'
        }}>
            <h1 style={{ fontSize: styles(windowDimensions.width).h1FontSize, fontWeight: 600, lineHeight: '4.5rem', marginBottom: styles(windowDimensions.width).mobile ? '2rem' : '4.5rem', width: '100%', textAlign: 'center' }}>Terms of use</h1>
        </div>
        <div style={{ 
                marginBottom: styles(windowDimensions.width).sectionMarginBottom, 
                paddingBottom: '3rem', 
                display: 'flex', 
                flexDirection: 'column', 
                width: styles(windowDimensions.width).fullPageWidth, 
                alignItems: 'center', 
                justifyContent: 'center',
                borderBottomWidth: '0.225rem',
                borderBottomStyle: 'solid',
                borderBottomColor: 'rgb(236, 240, 241)'
            }}>
            <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
            The i-want-to-study-engineering.org website (the “Website”) is provided and operated by the i-want-to-study-engineering.org project (the “Project”) at the Cambridge University Engineering Department of Trumpington Street, Cambridge, CB2 1RB, United Kingdom. The term “you” refers to the user or viewer of the Website.
            </p>
            <h2 style={{ fontSize: '1.8rem', fontWeight: 600, lineHeight: '2.25rem', marginBottom: '1.5rem' }}>Acceptance</h2>
            <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                Your use of the Website is subject to the following terms of use, which you are deemed to accept by using the Website. All personal information gathered from you in connection with your use of the Website is governed by the 
                <Link to="/privacy_policy" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: styles(windowDimensions.width).pFontSize, paddingRight: 5, paddingLeft: 5 }}>Privacy Policy</Link> and 
                <Link to="/cookies_policy" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: styles(windowDimensions.width).pFontSize, paddingRight: 5, paddingLeft: 5 }}>Cookies Policy</Link>. 
                Registration or logging in to this site is only available to people who are at least thirteen years old. If you attempt to register or login to this site you will be taken to be warranting that you are at least thirteen years old.
            </p>
            <h2 style={{ fontSize: '1.8rem', fontWeight: 600, lineHeight: '2.25rem', marginBottom: '1.5rem' }}>Copyright</h2>
            <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                Copyright and other intellectual property rights in the Website, including but not limited to text, images, artwork, data, data sets and other material (in whole or in part, the “Content”), the collection, arrangement, compilation, assembly and appearance of the Content (the “Compilation”) and the web delivery system and underlying software (the “Software”), are owned by the Project or by authors as indicated against the material and in 
            <Link to="/credits" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: styles(windowDimensions.width).pFontSize, paddingRight: 5, paddingLeft: 5 }}>Credits</Link> or are included in accordance with applicable law or third party consents.
            </p>
            <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                Subject to these terms of use, all rights in the Content, Compilation and Software are reserved to the Project. Written/text Content is licensed for reuse on the following terms:
            </p>
            <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                Copyright © 2013-2022 i-want-to-study-engineering.org Cambridge University Engineering Department 
                <a href="http://creativecommons.org/licenses/by-sa/3.0/deed.en_US"
                style={{ color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', paddingLeft: 5 }}>
                    <img src={'http://i.creativecommons.org/l/by-sa/3.0/80x15.png'} />
                </a>. Unless marked otherwise against specific material, the authors license use of their original content text posted on the Website under a 
                <a href="http://creativecommons.org/licenses/by-sa/3.0/deed.en_US"
                style={{ color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', paddingLeft: 5 }}>
                    Creative Commons Attribution-ShareAlike 3.0 Unported License
                </a>. If you use the content on your site, please link back to 
                <Link to="/terms_of_us" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: styles(windowDimensions.width).pFontSize, paddingRight: 5, paddingLeft: 5 }}>i-want-to-study-engineering.org</Link>.
            </p>
            <h2 style={{ fontSize: '1.8rem', fontWeight: 600, lineHeight: '2.25rem', marginBottom: '1.5rem' }}>Website acceptable use policy</h2>
            <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                You may use the Website only for lawful purposes. You may not use the Website:
            </p>
            <ul style={{ padding: 0, margin: 0, width: styles(windowDimensions.width).fullPageWidth, marginBottom: '1.5rem' }}>
                <li style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                    <p style={{ margin: 0, padding: 0 }}>In any way that breaches any applicable local, national or international law or regulation</p>
                </li>
                <li style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize  }}>
                    <p style={{ margin: 0, padding: 0 }}>In any way that is unlawful or fraudulent, or has any unlawful or fraudulent purpose or effect</p>
                </li>
                <li style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize  }}>
                    <p style={{ margin: 0, padding: 0 }}>For the purpose of harming or attempting to harm minors in any way</p>
                </li>
                <li style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize  }}>
                    <p style={{ margin: 0, padding: 0 }}>To send, knowingly receive, upload, download, transmit, use or re-use any material through or to the Website that is false, offensive, defamatory, threatening, obscene, unlawful or that infringes the rights of any person anywhere in the world and you waive any moral rights in respect of any such material</p>
                </li>
                <li style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize  }}>
                    <p style={{ margin: 0, padding: 0 }}>To transmit, or procure the sending of, any unsolicited or unauthorised advertising or promotional material or any other form of similar solicitation (spam)</p>
                </li>
                <li style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize  }}>
                    <p style={{ margin: 0, padding: 0 }}>To knowingly transmit any data, send or upload any material that contains viruses or any other harmful programs or similar computer code designed to adversely affect the operation of any computer software or hardware.</p>
                </li>
            </ul>
            <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                You also agree:
            </p>
            <ul style={{ padding: 0, margin: 0, width: styles(windowDimensions.width).fullPageWidth, marginBottom: '1.5rem' }}>
                <li style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                    <p style={{ margin: 0, padding: 0 }}>Not to reproduce, duplicate, copy or re-sell any part of the Website in contravention of the provisions of these terms of use</p>
                </li>
                <li style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize  }}>
                    <p style={{ margin: 0, padding: 0 }}>Not to access without authority, interfere with, damage or disrupt any part of the Website, any equipment or network on which the Website is stored, any Software used in the provision of the Website, or any equipment or network or software owned or used by any third party.</p>
                </li>
            </ul>
            <h2 style={{ fontSize: '1.8rem', fontWeight: 600, lineHeight: '2.25rem', marginBottom: '1.5rem' }}>Your username and password</h2>
            <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                Where you are invited to register or login on the Website, you may be allocated with or may be asked to choose a unique username and/or password that gives you access to your Website account. You are responsible for maintaining the confidentiality of your username and password, and are fully responsible for all activities that occur through your Website account. You agree to immediately notify the Project at the Cambridge University Engineering Department if you become aware of any unauthorised use of your password or Website account or any other breach of security. The Cambridge University Engineering Department cannot and will not be liable for any loss or damage arising from your failure to comply with these requirements.
            </p>
            <h2 style={{ fontSize: '1.8rem', fontWeight: 600, lineHeight: '2.25rem', marginBottom: '1.5rem' }}>No reliance</h2>
            <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                Unless stated otherwise, the Website may contain advice, opinions, statements or other information by various authors or organisations. Reliance upon any such advice, opinion, statement or other information is at your risk and to the extent permitted by law the Cambridge University Engineering Department disclaims all liability and responsibility arising from any reliance placed on such information by your use of the Website or by anyone who may be informed of such information.
            </p>
            <h2 style={{ fontSize: '1.8rem', fontWeight: 600, lineHeight: '2.25rem', marginBottom: '1.5rem' }}>Links to other websites</h2>
            <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                The Website may contain links to third party websites (“Linked Sites”). The Linked Sites are not owned or controlled by the Cambridge University Engineering Department and it is not responsible for the contents of any Linked Site. The inclusion of any link in the Website does not imply endorsement of any website or any association with its operators. You are responsible for viewing and abiding by the privacy, copyright and terms and conditions posted at the Linked Sites.
            </p>
            <h2 style={{ fontSize: '1.8rem', fontWeight: 600, lineHeight: '2.25rem', marginBottom: '1.5rem' }}>Trademarks</h2>
            <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                Any trademarks or logos displayed on the Website are the registered and unregistered trademarks of their owners. Nothing contained in the Website shall be construed as a grant of any licence or right to use the trademarks displayed on the Website without the prior written permission of the owner/s.
            </p>
            <h2 style={{ fontSize: '1.8rem', fontWeight: 600, lineHeight: '2.25rem', marginBottom: '1.5rem' }}>Disclaimer</h2>
            <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                The Website is provided on an “AS IS” and “AS AVAILABLE” basis and to the extent permitted by law the Cambridge University Engineering Department excludes all warranties whether express or implied by law including but not limited to the warranties of merchantability and fitness for a particular purpose. The Cambridge University Engineering Department does not accept any liability arising from any inaccuracy or omission in any Content or Compilation or otherwise on the Website or interruptions in the availability of the Website or any of its features or services.
            </p>
            <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                In no event shall the Cambridge University Engineering Department be liable to any party for any of the following losses or damages (whether such losses be foreseen, foreseeable, known or otherwise): loss of data, loss of revenue or anticipated profits, loss of business, loss of opportunity, loss of goodwill or injury to reputation, loss suffered by third parties, any indirect, special, incidental, or consequential damages arising out of the use of the Website or the Content or Compilation or Software or services provided by the Website whether or not amended by third parties and regardless of form of action. Nothing in these terms shall exclude or limit the liability of the Cambridge University Engineering Department for death or personal injury caused by its proven negligence or fraudulent misrepresentation.
            </p>
            <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                The Cambridge University Engineering Department has taken reasonable steps to ensure that the reproduction of material on the Website is done with the full consent of rights owners and/or in accordance with relevant legislative provisions. We welcome any information that would help us update or complete our records. If you are a rights holder and are concerned that you have found material on the Website for which you have not provided permission or is not covered by statutory exception please contact the Project at the Cambridge University Engineering Department in writing at the contact details provided at the end of these Terms of Use with your contact details, full details of the material’s creator, the exact and full URL where you found the material and a statement that you are the rights holder or are the authorised representative of the rights holder.
            </p>
            <h2 style={{ fontSize: '1.8rem', fontWeight: 600, lineHeight: '2.25rem', marginBottom: '1.5rem' }}>Variation of these Terms of Use</h2>
            <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                The Cambridge University Engineering Department reserves the right to vary these terms of use from time to time. Such variations become effective immediately upon the upload of the varied Terms of Use on the Website. By continuing to use the Website you will be deemed to accept such variations. If for any reason the Cambridge University Engineering Department believes that you have not complied with any of these Terms of Use, it may, in its sole discretion, cancel your access to the registration areas of the Website immediately and without giving you any advance notice.
            </p>
            <h2 style={{ fontSize: '1.8rem', fontWeight: 600, lineHeight: '2.25rem', marginBottom: '1.5rem' }}>General</h2>
            <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                The failure or delay by the Cambridge University Engineering Department to exercise or enforce any right in these terms of use does not waive the Cambridge University Engineering Department’s right to enforce that right.
            </p>
            <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                If any of these terms of use should be determined to be illegal, invalid or otherwise unenforceable by reason of the laws of any country in which these terms of use are intended to be effective, then to the extent and within that jurisdiction in which that term of use is illegal, invalid or unenforceable, it shall be severed and deleted from these terms of use and the remaining terms of use shall survive and remain in full force and effect and continue to be binding and enforceable.
            </p>
            <h2 style={{ fontSize: '1.8rem', fontWeight: 600, lineHeight: '2.25rem', marginBottom: '1.5rem' }}>Jurisdiction</h2>
            <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                These terms of use and all questions of construction, interpretation, validity and performance under these terms including non-contractual disputes or claims shall be governed by English law and subject to the exclusive jurisdiction of the English courts.
            </p>
            <h2 style={{ fontSize: '1.8rem', fontWeight: 600, lineHeight: '2.25rem', marginBottom: '1.5rem' }}>Contact details:</h2>
            <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                i-want-to-study-engineering.org <br />
                Professor Richard Prager  <br />
                Cambridge University Engineering Department  <br />
                Trumpington Street  <br />
                Cambridge  <br />
                CB2 1RB  <br />
                United Kingdom
            </p>
            <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                Telephone: +44 (0)1223 332771  <br />
                Email: info@i-want-to-study-engineering.org
            </p>
            <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                For further information about the i-want-to-study-engineering.org Project at Cambridge University Engineering Department, please contact Professor Richard Prager: rwp@eng.cam.ac.uk .
            </p>
            <p style={{ textAlign: 'left', fontSize: styles(windowDimensions.width).pFontSize }}>
                Last updated August 2022.
            </p>
        </div>
        <div style={{ 
            marginBottom: '4.5rem', 
            paddingBottom: '12rem', 
            display: 'flex', 
            flexDirection: 'row', 
            width: styles(windowDimensions.width).fullPageWidth,
            alignItems: 'center', 
            justifyContent: 'center',
        }}> 
            <span style={{ marginTop: '1.5rem' }}>
                <Link to="/terms_of_use" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingRight: 5 }}>Terms of use</Link> · 
                <Link to="/privacy_policy" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingRight: 5, paddingLeft: 5 }}>Privacy Policy</Link> · 
                <Link to="/cookies_policy" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingRight: 5, paddingLeft: 5 }}>Cookies Policy</Link> · 
                <Link to="/system_requirements" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingRight: 5, paddingLeft: 5 }}>System Requirements</Link> · 
                {/*<Link to="/faq" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingRight: 5, paddingLeft: 5 }}>FAQ</Link> · */}
                <Link to="/credits" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingLeft: 5 }}>Credits</Link>
            </span>
        </div>
    </div>
  );
}

export default TermsOfUse;