import firebase from 'firebase/app'
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import "firebase/storage";
import "firebase/app-check"
import "firebase/analytics";
//const { initializeAppCheck, ReCaptchaV3Provider } = require("firebase/app-check");

var firebaseConfig = {
  apiKey: "AIzaSyBD_CJIc-_puE2uCrc2i0TmfYw229GiBoc",
  authDomain: "i-want-to-study-engineering.org",
  projectId: "iwtse-1928f",
  storageBucket: "iwtse-1928f.appspot.com",
  messagingSenderId: "959196049375",
  appId: "1:959196049375:web:5ad14ec155872a2e14de6a",
  measurementId: "G-64XQ9MFH40"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// const appCheck = firebase.appCheck(); // This was working too with below

/*appCheck.activate(
  '6Ld5SckgAAAAAG9lwFgVB8hTDejbSiIQcLKHIK2D',

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
true);*/ // This was the one working

/*const appCheck = firebase.initializeAppCheck(app, {
  provider: new firebase.appCheck.ReCaptchaV3Provider('6Ld5SckgAAAAAG9lwFgVB8hTDejbSiIQcLKHIK2D'),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});*/

export const auth = firebase.auth;
export const firestore = firebase.firestore();
export const db = firebase.database();
export const storage = firebase.storage();
export const analytics = firebase.analytics();