import React, { useEffect, useState, useContext } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { useParams, useLocation } from 'react-router-dom';
//require('typeface-clear-sans')
import "fontsource-clear-sans"
import smalllogo from '../../smalllogo.svg';
import { ReactSVG } from 'react-svg'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory
  } from "react-router-dom";
import { auth, firestore } from "../../services/firebase"

import context from '../../context/context';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function styles(width) {
    let fullPageWidth = "80rem"
    let headerFontSize = "3rem"
    let h1FontSize = '3.7rem'
    let orFontSize = '2.2rem'
    let pFontSize = '1.3rem'
    let bottomLinks = '1.1rem'
    let mobile = false
    let sectionMarginBottom = '4.5rem'
    let topLinks = '1.1rem'
    let paddingRight = '3rem'
    let lineHeight = '1.5rem'
    if (width >= 1300) {
        fullPageWidth = "80rem"
        headerFontSize = "3rem"
        pFontSize = '1.3rem'
        h1FontSize = '3.7rem'
        orFontSize = '2.2rem'
        mobile = false
    } else if (width < 1300 && width >= 760) {
        fullPageWidth = "60rem"
        headerFontSize = "3rem"
        pFontSize = '1.3rem'
        h1FontSize = '3.7rem'
        orFontSize = '2.2rem'
        mobile = false
    } else if (width < 760 ) {
        fullPageWidth = "20rem"
        headerFontSize = "1.75rem"
        pFontSize = '1rem'
        h1FontSize = '2.5rem'
        orFontSize = '1.5rem'
        mobile = true
        sectionMarginBottom  = '2rem'
        topLinks = '0.9rem'
        paddingRight = '1rem'
        lineHeight = '1.1rem'
    }
    return { fullPageWidth, headerFontSize, pFontSize, mobile, sectionMarginBottom, h1FontSize, orFontSize, topLinks, paddingRight, lineHeight }
}

function SelectedTopicList() {
  const history = useHistory(); 
  const [questions, setQuestions] = useState([])
  const [title, setTitle] = useState('')
  const [allQuestionBlocks, setAllQuestionBlocks] = useState([])
  const [questionsAnsweredTopic, setQuestionsAnsweredTopic] = useState([0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]) //possibly make this on the fly
  const [totalCompletedQuestions, setTotalCompletedQuestions] = useState(0)
  const [altTitles, setAltTitles] = useState([])
  const [showError, setShowError] = useState(false)
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const { completed } = useContext(context)
  let { topicId } = useParams();

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
}, []);

  useEffect(async () => {
    //const user = auth().currentUser;
    let localQuestions = []
    const completedQuestions = Object.fromEntries(
        Object.entries(completed).filter(([key, value]) => value === true) )
    setTotalCompletedQuestions(Object.keys(completedQuestions).length)
    const completeQuestionsIds = Object.keys(completedQuestions)
    const updatedQuestionsAnsweredTopic = questionsAnsweredTopic
    if (topicId === "all") {
        const snapshot = await firestore.collection(`problemIndex`).get();
        snapshot.forEach((doc) => {
            //console.log("all init", Object.values(doc.data()))
            setAllQuestionBlocks(Object.values(doc.data()).sort((a,b) => (a.id> b.id) ? 1 : ((b.id > a.id) ? -1 : 0)))
            Object.values(doc.data()).forEach((data) => {
                const currentSectionIndex = parseInt(data.id.replace('sb', ''))-1
                const currentSectionQuestionsIds = Object.keys(data.questions)
                const intersection = completeQuestionsIds.filter(value => { 
                    const returnVal = currentSectionQuestionsIds.includes(value)
                    return returnVal
                })
                updatedQuestionsAnsweredTopic[currentSectionIndex] = intersection.length
            })
            setQuestionsAnsweredTopic(updatedQuestionsAnsweredTopic)
        })
    } else if (topicId === "alph") {
        const snapshot = await firestore.collection(`problemIndex`).get();
        let allAlphQuestions = []
        snapshot.forEach((doc) => {
            //console.log("alph init", doc.data());
            Object.values(doc.data()).forEach((data) => {
                allAlphQuestions = [...allAlphQuestions, ...Object.values(data.questions)]
            })
        })
        allAlphQuestions.sort((a,b) => (a.id> b.id) ? 1 : ((b.id > a.id) ? -1 : 0))
        setQuestions(allAlphQuestions)
    } else if (["sb1", "sb2", "sb3", "sb4", "sb5", "sb6", "sb7", "sb8", "sb9", "sb10", "sb11", "sb12", "sb13", "sb14", "sb15"].includes(topicId)) {
        const snapshot = await firestore.collection(`problemIndex`).get();
        snapshot.forEach((doc) => {
            localQuestions = (Object.values(doc.data()[topicId].questions))
            setTitle(doc.data()[topicId].title)
        });
        localQuestions.sort((a,b) => (a.id> b.id) ? 1 : ((b.id > a.id) ? -1 : 0))
        setQuestions(localQuestions)
    } else {
        history.push(`/`)
    }

    /*
        else if (topicId === "alt") {
        const questionsSnapshot = await firestore.collection(`Questions`).get();
        let altTitles = []
        questionsSnapshot.forEach((doc) => {
            console.log("alt init", Object.keys(doc.data()), Object.values(doc.data())[0].hint?.title);
            altTitles = [...altTitles, ...Object.values(doc.data())[0].hint?.title]
            //Object.values(doc.data()).forEach((data) => {
            //    allAlphQuestions = [...allAlphQuestions, ...Object.values(data.questions)]
            //})
        })
        let altTitlesUnique = altTitles.filter((value, index, self) =>
            index === self.findIndex((t) => {
                console.log(t.rowTitle, value.rowTitle)
                return (t.rowTitle === value.rowTitle && t.linkTitle === value.linkTitle)
            }))
        altTitlesUnique = altTitlesUnique.filter((title) => title.rowTitle !== "Alternate solution strategy" && title.rowTitle !== "Alternative solution strategy" && title.rowTitle !== "Alternative strategy"  && title.rowTitle !== "General solution")
        altTitlesUnique.sort((a,b) => (a.rowTitle> b.rowTitle) ? 1 : ((b.rowTitle > a.rowTitle) ? -1 : 0))
        setAltTitles(altTitlesUnique)
    }
    */
  }, [completed]);
  return (
    <div style={{ display: 'flex', flexDirection: 'column',  width: windowDimensions.width-(styles(windowDimensions.width).mobile ? 8 : 64), margin: 'auto', flex: 1, alignItems: 'center', height: '100vh', fontFamily: "Clear Sans, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif" }}>
        <div style={{ 
            marginBottom: styles(windowDimensions.width).mobile ? '2rem' : '7.5rem', 
            display: 'flex', 
            flexDirection: 'row', 
            width: '100%',
            borderBottomWidth: '0.225rem',
            borderBottomStyle: 'solid',
            borderBottomColor: 'rgb(236, 240, 241)',
        }}>
            <div style={{ display: 'flex', flex: 2, height: '4.5rem' }}>
                <Link to="/"> 
                    <ReactSVG beforeInjection={(svg) => {
                        svg.classList.add('svg-class-name')
                        svg.setAttribute('style', 'height: 4.5rem; width: 4rem; padding: 0.75rem 0 0.75rem 0.75rem')
                    }} src={smalllogo} style={{marginBottom: '0.75rem'}} />
                </Link>
            </div>
            <div style={{ display: 'flex', flex: 4, alignItems: 'center', justifyContent: 'flex-end' }}>
                <div style={{ marginRight: '-1rem', display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Link style={{ display: 'flex', paddingRight: styles(windowDimensions.width).paddingRight, fontSize: styles(windowDimensions.width).topLinks, fontWeight: 400, lineHeight: styles(windowDimensions.width).lineHeight, opacity: 0.5, color: '#000' }} to="/questionlist">
                        Problem Index
                    </Link>
                    <Link style={{ display: 'flex', paddingRight: styles(windowDimensions.width).paddingRight, fontSize: styles(windowDimensions.width).topLinks, fontWeight: 400, lineHeight: styles(windowDimensions.width).lineHeight, opacity: 0.5, color: '#000' }} onClick={(e) => {
                        e.preventDefault();
                        const completedQuestions = Object.fromEntries(
                            Object.entries(completed).filter(([key, value]) => value === false) )
                        //console.log(Object.keys(completedQuestions).length*Math.random())
                        let randomIndex = 0
                        let linkTo = `/q/${Object.keys(completed)[randomIndex]}`
                        //console.log(Object.keys(completedQuestions).length)
                        if (Object.keys(completedQuestions).length*Math.random() === 0) {
                            randomIndex = Math.floor((Object.keys(completed).length)*Math.random())
                            linkTo = `/q/${Object.keys(completed)[randomIndex]}`
                        } else {
                            randomIndex = Math.floor((Object.keys(completedQuestions).length)*Math.random())
                            linkTo = `/q/${Object.keys(completedQuestions)[randomIndex]}`
                        }
                        
                        //console.log("linkTo", linkTo)
                        history.push(`${linkTo}`)
                    }}>
                        Problem Generator
                    </Link>
                    {auth().currentUser ? 
                    <Link style={{ display: 'flex', paddingRight: styles(windowDimensions.width).paddingRight, fontSize: styles(windowDimensions.width).topLinks, fontWeight: 400, lineHeight: styles(windowDimensions.width).lineHeight, opacity: 0.5, color: '#000' }} onClick={(e) => {
                        e.preventDefault()
                        auth().signOut()
                    }}>{`Logout`}<br />{auth().currentUser.email}</Link>
                    : <Link style={{ display: 'flex', paddingRight: styles(windowDimensions.width).paddingRight, fontSize: styles(windowDimensions.width).topLinks, fontWeight: 400, lineHeight: styles(windowDimensions.width).lineHeight, opacity: 0.5, color: '#000' }} to="/login">
                        {`Login or Sign Up`}
                    </Link>}
                </div>
            </div>
        </div>
        {topicId === 'all' && !showError && <div style={{ 
            marginBottom: '2rem', 
            paddingBottom: '0rem', 
            display: 'flex', 
            flexDirection: 'column', 
            width: '100%', 
            alignItems: 'flex-start', 
            justifyContent: 'center',
        }}>
            <h1 style={{ fontSize: styles(windowDimensions.width).mobile ? '2rem' : '3.7rem', fontWeight: 600, lineHeight: styles(windowDimensions.width).mobile ? '2.25rem' : '4.5rem', marginBottom: styles(windowDimensions.width).mobile ? '1rem' : '4.5rem', width: '100%', textAlign: 'center' }}>All Problems By Topic</h1>
        </div>}
        {topicId === 'alph' && !showError && <div style={{ 
            marginBottom: '2rem', 
            paddingBottom: '0rem', 
            display: 'flex', 
            flexDirection: 'column', 
            width: '100%', 
            alignItems: 'center', 
            justifyContent: 'center',
        }}>
            <h1 style={{ fontSize: styles(windowDimensions.width).mobile ? '2rem' : '3.7rem', fontWeight: 600, lineHeight: styles(windowDimensions.width).mobile ? '2.25rem' : '4.5rem', marginBottom: styles(windowDimensions.width).mobile ? '1rem' : '4.5rem', width: '100%', textAlign: 'center' }}>All Problems in Alphabetical Order</h1>
        </div>}
        {topicId === 'all' && !showError && allQuestionBlocks.map((questions) => {
            const numberOfQuestions = questions.questions ? Object.keys(questions.questions).length : 0
            return (<div style={{ width: '100%' }}>
                <div style={{ 
                    marginBottom: '1.25rem', 
                    paddingBottom: '0rem', 
                    display: 'flex', 
                    flexDirection: 'column', 
                    width: '100%', 
                }}>
                    <h2 style={{ color: '#000', fontWeight: 600 }}><span>{`${questions.title} `}</span><span style={{ color: '#AAAAAA', fontWeight: 100 }}>{`· ${questionsAnsweredTopic[parseInt(questions.id.replace('sb', '')-1)]}/${numberOfQuestions}`}</span></h2>
                </div>
                <div style={{ 
                    display: 'flex', 
                    width: '100%', 
                }}>
                    <div style={{ flexDirection: 'column', marginBottom: '5rem' }}>
                    {Object.values(questions.questions).map((question) => {
                        return (
                            <div>
                            <Link style={{ display: 'flex', textDecoration: 'none', color: '#000', margin: 0, padding: 0, flexDirection: 'row' }} to={{pathname: `/q/${question.id}`}}>
                                <p style={{ fontSize: 20, margin: 0, padding: 0 }}>{question.title}</p>{completed[question.id] && <span style={{ fontSize: 16, width: 20, height: 20, padding: 0, position: 'relative', display: 'block', float: 'left', color: '#f2b01e', overflow: 'hidden' }}>&#x2605;</span>}
                            </Link>
                            </div>
                        )
                    })} 
                    </div>
                </div>
            </div>)
        })}
        
        {topicId === 'alph' && !showError && <div style={{ 
            marginBottom: '1.25rem', 
            paddingBottom: '0rem', 
            display: 'flex', 
            flexDirection: 'column', 
            width: '100%', 
        }}>
            <h2 style={{ color: '#000', fontWeight: 600, fontSize: styles(windowDimensions.width).headerFontSize }}><span>{`Problems `}</span><span style={{ color: '#AAAAAA', fontWeight: 100 }}>{`· ${totalCompletedQuestions}/${questions.length}`}</span></h2>
        </div>}
        {topicId === 'alph' && !showError && <div style={{ 
            display: 'flex', 
            width: '100%', 
            borderBottomWidth: '0.225rem',
            borderBottomStyle: 'solid',
            borderBottomColor: 'rgb(236, 240, 241)' 
        }}>
            <div style={{ flexDirection: 'column', marginBottom: '5rem' }}>
            {questions.map((question) => {
                return (
                    <div>
                    <Link style={{ display: 'flex', textDecoration: 'none', color: '#000', margin: 0, padding: 0, flexDirection: 'row' }} to={{pathname: `/q/${question.id}`}}>
                        <p style={{ fontSize: 20, margin: 0, padding: 0 }}>{question.title}</p>{completed[question.id] && <span style={{ fontSize: 16, width: 20, height: 20, padding: 0, position: 'relative', display: 'block', float: 'left', color: '#f2b01e', overflow: 'hidden' }}>&#x2605;</span>}
                    </Link>
                    </div>
                )
            })} 
            </div>
        </div>}
        {/*topicId === 'alt' && <div style={{ 
            display: 'flex', 
            width: '80rem', 
            borderBottomWidth: '0.225rem',
            borderBottomStyle: 'solid',
            borderBottomColor: 'rgb(236, 240, 241)' 
        }}>
            <div style={{ flexDirection: 'column', marginBottom: '5rem' }}>
            {altTitles.map((question) => {
                return (
                    <div>
                    <Link style={{ display: 'flex', textDecoration: 'none', color: '#000', margin: 0, padding: 0, flexDirection: 'row' }} to={{pathname: `/topic/alt/${question.linkTitle}`}}>
                        <p style={{ fontSize: 20, margin: 0, padding: 0 }}>{question.rowTitle}</p>
                    </Link>
                    </div>
                )
            })} 
            </div>
        </div>*/}
        {topicId !== 'all' && topicId !== 'alph' && !showError &&  <div style={{ 
            marginBottom: '1.25rem', 
            paddingBottom: '0rem', 
            display: 'flex', 
            flexDirection: 'column', 
            width: '100%', 
        }}>
            <h2 style={{ color: '#000', fontWeight: 600 }}>{title}</h2>
        </div>}
        {topicId !== 'all' && topicId !== 'alph' && !showError && <div style={{ 
            display: 'flex', 
            width: '100%', 
            borderBottomWidth: '0.225rem',
            borderBottomStyle: 'solid',
            borderBottomColor: 'rgb(236, 240, 241)' 
        }}>
            <div style={{ flexDirection: 'column', marginBottom: '5rem' }}>
            {questions.map((question) => {
                //console.log(completed[question.id])
                return (
                    <div>
                    <Link style={{ display: 'flex', textDecoration: 'none', color: '#000', margin: 0, padding: 0, flexDirection: 'row' }} to={{pathname: `/q/${question.id}`}}>
                        <p style={{ fontSize: 20, margin: 0, padding: 0 }}>{question.title}</p>{completed[question.id] && <span style={{ fontSize: 16, width: 20, height: 20, padding: 0, position: 'relative', display: 'block', float: 'left', color: '#f2b01e', overflow: 'hidden' }}>&#x2605;</span>}
                    </Link>
                    </div>
                )
            })} 
            </div>
        </div>}
        <div style={{ 
            marginBottom: '4.5rem', 
            paddingBottom: '12rem', 
            display: 'flex', 
            flexDirection: 'row', 
            width: styles(windowDimensions.width).fullPageWidth,
            alignItems: 'center', 
            justifyContent: 'center',
        }}> 
            <span style={{ marginTop: '1.5rem' }}>
                <Link to="/terms_of_use" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingRight: 5 }}>Terms of use</Link> · 
                <Link to="/privacy_policy" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingRight: 5, paddingLeft: 5 }}>Privacy Policy</Link> · 
                <Link to="/cookies_policy" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingRight: 5, paddingLeft: 5 }}>Cookies Policy</Link> · 
                <Link to="/system_requirements" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingRight: 5, paddingLeft: 5 }}>System Requirements</Link> · 
                {/*<Link to="/faq" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingRight: 5, paddingLeft: 5 }}>FAQ</Link> · */}
                <Link to="/credits" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingLeft: 5 }}>Credits</Link>
            </span>
        </div>
    </div>
  );
}

export default SelectedTopicList;