import React, { useEffect, useState, useContext, useCallback } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
//require('typeface-clear-sans')
import "fontsource-clear-sans"
import smalllogo from '../../smalllogo.svg';
import { ReactSVG } from 'react-svg'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory
  } from "react-router-dom";
import GlobalState from '../../context/GlobalState';
import context from '../../context/context';
import { auth, firestore } from "../../services/firebase";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function styles(width) {
    let fullPageWidth = "80rem"
    let headerFontSize = "3rem"
    let h1FontSize = '3.7rem'
    let orFontSize = '2.2rem'
    let pFontSize = '1.3rem'
    let bottomLinks = '1.1rem'
    let mobile = false
    let sectionMarginBottom = '4.5rem'
    let topLinks = '1.1rem'
    let paddingRight = '3rem'
    let lineHeight = '1.5rem'
    if (width >= 1300) {
        fullPageWidth = "80rem"
        headerFontSize = "3rem"
        pFontSize = '1.3rem'
        h1FontSize = '3.7rem'
        orFontSize = '2.2rem'
        mobile = false
    } else if (width < 1300 && width >= 760) {
        fullPageWidth = "60rem"
        headerFontSize = "3rem"
        pFontSize = '1.3rem'
        h1FontSize = '3.7rem'
        orFontSize = '2.2rem'
        mobile = false
    } else if (width < 760 ) {
        fullPageWidth = "20rem"
        headerFontSize = "1.75rem"
        pFontSize = '1rem'
        h1FontSize = '2.5rem'
        orFontSize = '1.5rem'
        mobile = true
        sectionMarginBottom  = '2rem'
        topLinks = '0.9rem'
        paddingRight = '1rem'
        lineHeight = '1.1rem'
    }
    return { fullPageWidth, headerFontSize, pFontSize, mobile, sectionMarginBottom, h1FontSize, orFontSize, topLinks, paddingRight, lineHeight }
}

function ALevelQuestionList() {
  const history = useHistory();
  const [topics, setTopics] = useState([])
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());


  const [questionsAnsweredTopic, setQuestionsAnsweredTopic] = useState([0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]) //possibly make this on the fly
  const { aqcompleted, setAllCompleted, aqcompletedorder } = useContext(context);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
   }, []);

  useEffect(async () => {
    //const user = auth().currentUser;
    const snapshot = await firestore.collection('aLevelProblemIndex').get();
    let localTopics = []
    const completedQuestions = Object.fromEntries(
        Object.entries(aqcompleted).filter(([key, value]) => value === true) )
    const completeQuestionsIds = Object.keys(completedQuestions)
    const updatedQuestionsAnsweredTopic = questionsAnsweredTopic
    snapshot.forEach((doc) => {
        //console.log(doc.id, '=>', doc.data());
        Object.values(doc.data()).forEach((data) => {
            const currentSectionIndex = parseInt(data.id.replace('sb', ''))-1
            const currentSectionQuestionsIds = Object.keys(data.questions)
            const intersection = completeQuestionsIds.filter(value => { 
                const returnVal = currentSectionQuestionsIds.includes(value)
                return returnVal
            })
            updatedQuestionsAnsweredTopic[currentSectionIndex] = intersection.length
        })
        setQuestionsAnsweredTopic(updatedQuestionsAnsweredTopic)
        
        //const idsForProblem = Object.keys(doc.data().questions)
        const topicValuesArray = Object.values(doc.data())
        topicValuesArray.sort((a,b) => (a.id> b.id) ? 1 : ((b.id > a.id) ? -1 : 0))
        setTopics(topicValuesArray)
    });

    //var query = firestore().collection('Users').doc(user.uid);

    /*query.onSnapshot({
        next: (querySnapshot) => {
          setUpShop(querySnapshot.data().shopCreated)                 
        },
    });*/
  }, [aqcompleted]);
  return (
    <div style={{ display: 'flex', flexDirection: 'column',  width: windowDimensions.width-(styles(windowDimensions.width).mobile ? 8 : 64), flex: 1, alignItems: 'center', height: '100vh', fontFamily: "Clear Sans, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif", margin: 'auto' }}>
        <div style={{ 
            marginBottom: styles(windowDimensions.width).mobile ? '2rem' : '6rem',
            display: 'flex', 
            flexDirection: 'row', 
            borderBottomWidth: '0.225rem',
            borderBottomStyle: 'solid',
            borderBottomColor: 'rgb(236, 240, 241)',
            width: '100%'
        }}>
            <div style={{ display: 'flex', flex: 2, height: '4.5rem' }}>
                <Link to="/">        
                    <ReactSVG beforeInjection={(svg) => {
                        svg.classList.add('svg-class-name')
                        svg.setAttribute('style', 'height: 4.5rem; width: 4rem; padding: 0.75rem 0 0.75rem 0.75rem')
                    }} src={smalllogo} style={{marginBottom: '0.75rem'}} />
                </Link>
            </div>
            <div style={{ display: 'flex', flex: 4, alignItems: 'center', justifyContent: 'flex-end' }}>
                <div style={{ marginRight: '-1rem', display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Link style={{ display: 'flex', paddingRight: styles(windowDimensions.width).paddingRight, fontSize: styles(windowDimensions.width).topLinks, fontWeight: 400, lineHeight: styles(windowDimensions.width).lineHeight, color: '#000' }} to="/alevelquestionlist">
                        A-level Q Index
                    </Link>
                    <Link style={{ display: 'flex', paddingRight: styles(windowDimensions.width).paddingRight, fontSize: styles(windowDimensions.width).topLinks, fontWeight: 400, lineHeight: styles(windowDimensions.width).lineHeight, opacity: 0.5, color: '#000' }} onClick={(e) => {
                        e.preventDefault();
                        
                        //history.push('/your-route');
                        history.push(`/aq/${aqcompletedorder[0]}`)
                    }}>
                        Next A-Level Q
                    </Link>
                    {auth().currentUser ? 
                    <Link style={{ display: 'flex', paddingRight: styles(windowDimensions.width).paddingRight, fontSize: styles(windowDimensions.width).topLinks, fontWeight: 400, lineHeight: styles(windowDimensions.width).lineHeight, opacity: 0.5, color: '#000' }} onClick={(e) => {
                        e.preventDefault()
                        auth().signOut()
                    }}>{`Logout`}<br />{auth().currentUser.email}</Link>
                    : <Link style={{ display: 'flex', paddingRight: styles(windowDimensions.width).paddingRight, fontSize: styles(windowDimensions.width).topLinks, fontWeight: 400, lineHeight: styles(windowDimensions.width).lineHeight, opacity: 0.5, color: '#000' }} to="/login">
                        {`Login or Sign Up`}
                    </Link>}
                </div>
            </div>
        </div>
        <div style={{ 
            marginBottom: '2rem', 
            paddingBottom: '0rem', 
            display: 'flex', 
            flexDirection: 'column', 
            width: '100%', 
            alignItems: 'center', 
            justifyContent: 'center',
            borderBottomWidth: '0.225rem',
            borderBottomStyle: 'solid',
            borderBottomColor: 'rgb(236, 240, 241)'
        }}>
            <h1 style={{ fontSize: styles(windowDimensions.width).h1FontSize, fontWeight: 600, lineHeight: '4.5rem', marginBottom: styles(windowDimensions.width).mobile ? '2rem' : '4.5rem', width: '100%', textAlign: 'center' }}>A-level Problem Index</h1>
        </div>
        <div style={{ 
            display: 'flex', 
            flexDirection: 'column',
            width: '100%', 
            borderBottomWidth: '0.225rem',
            borderBottomStyle: 'solid',
            borderBottomColor: 'rgb(236, 240, 241)' 
        }}>
            {topics.map((topic, index) => {
                const numberOfQuestions = topic.questions ? Object.keys(topic.questions).length : 0
                return (
                    <Link key={index} style={{ textDecoration: 'none', paddingLeft: '1.5rem', marginTop: styles(windowDimensions.width).mobile ? '0.75rem' : '1.5rem', marginBottom: styles(windowDimensions.width).mobile ? '0.75rem' : '1.5rem' }} to={{pathname: `/aqtopic/${topic.id}`}}>
                        <h2 style={{ color: '#000', fontWeight: 600,  fontSize: styles(windowDimensions.width).mobile ? '1.5rem' : '2rem' }}><span>{`${topic.title} `}</span><span style={{ color: '#AAAAAA', fontWeight: 100,  fontSize: styles(windowDimensions.width).mobile ? '1.5rem' : '2rem' }}>{`· ${questionsAnsweredTopic[parseInt(topic.id.replace('sb', '')-1)]}/${numberOfQuestions}`}</span></h2>
                    </Link>
                )
            })} 
        </div>
        <div style={{ 
            display: 'flex', 
            flexDirection: 'column',
            width: '100%', 
            borderBottomWidth: '0.225rem',
            borderBottomStyle: 'solid',
            borderBottomColor: 'rgb(236, 240, 241)' 
        }}>
            <Link style={{ textDecoration: 'none', paddingLeft: '1.5rem', marginTop: styles(windowDimensions.width).mobile ? '0.75rem' : '1.5rem', marginBottom: styles(windowDimensions.width).mobile ? '0.75rem' : '1.5rem' }} to={{pathname: `/aqtopic/all`}}>
                <h2 style={{ color: '#000', fontWeight: 600, fontSize: styles(windowDimensions.width).mobile ? '1.5rem' : '2rem' }}><span>{`All Questions By Paper`}</span></h2>
            </Link>
            {false && <Link style={{ textDecoration: 'none', paddingLeft: '1.5rem', marginTop: '1.5rem', marginBottom: '1.5rem' }} to={{pathname: `/aqtopic/alph`}}>
                <h2 style={{ color: '#000', fontWeight: 600 }}><span>{`All Problems In Alphabetical Order`}</span></h2>
            </Link>}
            {false && <Link style={{ textDecoration: 'none', paddingLeft: '1.5rem', marginTop: '1.5rem', marginBottom: '6rem' }} to={{pathname: `/aqtopic/alt`}}>
                <h2 style={{ color: '#000', fontWeight: 600 }}><span>{`Alphabetical List of General Subjects`}</span></h2>
            </Link>}
        </div>
        <div style={{ 
            marginBottom: '4.5rem', 
            paddingBottom: '12rem', 
            display: 'flex', 
            flexDirection: 'row', 
            width: styles(windowDimensions.width).fullPageWidth,
            alignItems: 'center', 
            justifyContent: 'center',
        }}> 
            <span style={{ marginTop: '1.5rem' }}>
                <Link to="/terms_of_use" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingRight: 5 }}>Terms of use</Link> · 
                <Link to="/privacy_policy" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingRight: 5, paddingLeft: 5 }}>Privacy Policy</Link> · 
                <Link to="/cookies_policy" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingRight: 5, paddingLeft: 5 }}>Cookies Policy</Link> · 
                <Link to="/system_requirements" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingRight: 5, paddingLeft: 5 }}>System Requirements</Link> · 
                {/*<Link to="/faq" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingRight: 5, paddingLeft: 5 }}>FAQ</Link> · */}
                <Link to="/credits" style={{ borderColor: 'rgba(231, 76, 60, 0)', color: '#000000', textDecoration: 'none', borderBottom: '0.15rem solid #cfd9db', fontSize: '1.1rem', paddingLeft: 5 }}>Credits</Link>
            </span>
        </div>
    </div>
  );
}

export default ALevelQuestionList;